import React from 'react'
import { ClickAwayListener } from '@mui/base';

const GuestsSearch = ({showGuestDropDown, setShowGuestDropDown, guestInputText, guests, setGuestInputText}) => {
  return (
        <ClickAwayListener onClickAway={() => setShowGuestDropDown(false)} mouseEvent={'onMouseDown'}>
            <div className='apartment-container' id='four'>
                <div className="flex-row center gap-10 apartment-text">
                    <div className='flex-row center gap-10'  onClick={()=>{
                            setShowGuestDropDown(!showGuestDropDown);
                        }}>
                        <div className='item-flex'><i className="fa-solid fa-users-line"></i></div>
                        <input type="text" placeholder={guestInputText} className='disabled'/>
                        {!showGuestDropDown && <i class="fa-solid fa-angle-down"></i>} {showGuestDropDown && <i class="fa-solid fa-angle-up"></i>}
                        <div className='item-flex'> <div className='thin-vertical'></div></div>
                    </div>
                </div>
                <div className={`apartment-menu-overlay flex-column center gap-10 guests ${showGuestDropDown ? "visible" : "hidden"}`}>
                    {guests.map((item, index) =>
                        <div key={index} className="cursor"  onClick={()=>{
                            setGuestInputText(item)}}
                            >
                            {item}
                        </div>
                    )}
                </div>
            </div>
        </ClickAwayListener>
  )
}

export default GuestsSearch