import React from 'react'
import { ClickAwayListener } from '@mui/base';


const ApartmentSearchMobile = ({showApartmentDropDown, setShowApartmentDropDown, apartmentInputText, setApartmentInputText, apartments,selectedApartment,
  setSelectedApartment}) => {
  return (
                <ClickAwayListener onClickAway={() => setShowApartmentDropDown(false)} mouseEvent={'onMouseDown'}>
                    <li className='flex-column gap-10 search-input-item center position-relative'>
                      <div className="flex-row gap-10 center  guest-container-mobile"  onClick={()=> setShowApartmentDropDown(true)}>
                        <div className="flex-row center gap-10">
                          <i class="fa-solid fa-house"></i>
                          <input type="text" placeholder={apartmentInputText}/>
                        </div>
                        <i class="fa-solid fa-angle-down"></i>
                      </div>
                      {/* <div className={`apartment-menu-overlay flex-column center gap-10 ${showApartmentDropDown ? 'visible' : 'hidden'} color-black`}>
                        {apartments.map((item, index) =>
                          <div key={index} className="cursor" onClick={()=> setApartmentInputText(item)}>{item} </div>
                        )}
                      </div> */}


                      <div className={`apartment-menu-overlay flex-column center gap-10 ${showApartmentDropDown ? "visible" : "hidden"} color-black`}>
          {apartments.map((item, index) => {
            const { id, name } = item; // Destructure the unique ID and name from the apartment object

            return (
              <div
                key={id} // Use the unique ID as the key
                className={`cursor ${selectedApartment === item ? "selected" : ""}`}
                onClick={() => {
                  setApartmentInputText(name); // Use the name from the apartment object
                  setSelectedApartment(item); // Set the selected apartment with the name and unique ID
                }}
              >
                {name}
              </div>
            );
          })}
        </div>

                    </li>
                </ClickAwayListener>

  )
}

export default ApartmentSearchMobile

