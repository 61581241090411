import React from 'react'
import { useNavigate } from 'react-router-dom';

const MobileFooter = () => {
    const navigate = useNavigate();

  return (
        <div className="flex-row  flex-space-between grid-four mobile">
            <div className="flex-column justify-space-between gap-20">
                <div className="logo-footer align-flex-start cursor" onClick={() => navigate('/')}>
                    <img src="/images/logo-footer.svg" alt="" />
                </div>
                <div className="flex-column gap-20">
                    <div className="footer-header">Quicklinks.</div>
                    <div className="flex-column gap-10">
                        <div className="footer-text">Apartments</div>
                        <div className="footer-text">Chauffeur</div>
                        <div className="footer-text">Catering</div>
                        <div className="footer-text">Laundry</div>
                        <div className="footer-text">Mini Lounge</div>
                    </div>
                </div>
                <div className="barcode-container flex-column align-flex-end">
                    <div className="barcode-container"><img src="/images/attraction.png" alt="" /></div>
                    <div className="footer-text">Scan our Bar Code</div>
                </div>
            </div>

            <div className="flex-column gap-20">
                <div className="footer-button-container">
                    <div className="button white-button footer-button sm-long">
                    <a href="/files/profile.pdf" style={{width:'100px'}} target='_blank' >
                    Download our Profile
                    </a>
                    </div>
                    <div className="button white-button footer-button sm-long mt-2">
                        <a href="/files/attraction.pdf" style={{ width: '100px' }} target='_blank' >
                            View our Attraction Sites
                        </a>
                    </div>
                </div>
                <div className="flex-column gap-20">
                    <div className="footer-header">Terms & Policies</div>
                    <div className="flex-column gap-10">
                        <div className="footer-text cursor" onClick={() => navigate('/terms')}>Terms & Conditions</div>
                    </div>
                </div>
                <div className="flex-row justify-space-between gap-40 grid-footer">
                    <div className="flex-column justify-space-between align-self-flex-start">
                        <div className="flex-column gap-20">
                            <div className="flex-row gap-10 orange">
                            <div className="footer-icon cursor" onClick={()=>{
                                window.location.assign('https://instagram.com/milanosignatureint?igshid=MzRlODBiNWFlZA==');
                            }}><i class="fa-brands fa-instagram"></i></div>
                            <div className="footer-icon cursor" onClick={()=>{
                                window.location.assign('https://www.facebook.com/profile.php?id=100078211172407&mibextid=ZbWKwL');
                            }}><i class="fa-brands fa-facebook"></i></div>
                            <div className="footer-icon cursor" onClick={()=>{
                                window.location.assign('https://youtube.com/@milanosignature511');
                            }}><i class="fa-brands fa-youtube"></i></div>
                            <div className="footer-icon cursor" onClick={()=>{
                                window.location.assign('https://www.linkedin.com/in/milano-signature-312a80234');
                            }}><i class="fa-brands fa-linkedin"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row center footer-arrow" onClick={()=>{
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }}>
                        <i class="fa-solid fa-arrow-up"></i>
                    </div>
                </div>
            </div>

        </div>
  )
}

export default MobileFooter