import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAllApartments } from '../../features/apartments/apartmentSlice';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApartmentInfo = ({ infoPage }) => {
    const { id } = useParams(); // Extract the apartment ID from the URL
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        checkIn: '',
        checkOut: '',
        guests: 'individuals',
        getDays: '',
        photo: '',
        countryCode: '+234',
        amount: 0,
        apartmentName: '',
    });

    const { apartments, isLoading, isError } = useSelector((state) => state.apartments);
    const apartment = apartments.find((apartment) => apartment.name === id);

    // Fetch all apartments on mount
    useEffect(() => {
        dispatch(getAllApartments());
    }, [dispatch]);

    // Update mainImage and formData when apartment data is available
    useEffect(() => {
        const apartment = apartments.find((apartment) => apartment.name === id);
        if (apartment) {
            setMainImage(apartment.images[0]);
            setFormData(prevFormData => ({
                ...prevFormData,
                amount: apartment.price,
                apartmentName: apartment.name,
            }));
        }
    }, [apartments, id]);

    // Handle thumbnail click
    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    // Calculate number of days between check-in and check-out
    const getDays = (checkIn, checkOut) => {
        if (!checkIn || !checkOut) return '';
        const checkInTime = new Date(checkIn).getTime();
        const checkOutTime = new Date(checkOut).getTime();
        const timeDiff = checkOutTime - checkInTime;
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDiff;
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update form data
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [name]: value };

            // Calculate days if check-in or check-out changes
            if (name === 'checkIn' || name === 'checkOut') {
                const days = getDays(
                    name === 'checkIn' ? value : newFormData.checkIn,
                    name === 'checkOut' ? value : newFormData.checkOut
                );
                newFormData.getDays = days;
            }

            return newFormData;
        });
    };

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <svg
                    className="animate-spin w-16 h-16 text-[#876B23]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle className="stroke-current stroke-2" cx="12" cy="12" r="10" stroke="currentColor" />
                </svg>
                <p>Loading .....</p>
            </div>
        );
    }

    if (isError) {
        return <div>Error loading apartments.</div>;
    }

    if (!mainImage) {
        return <div>Apartment not found.</div>;
    }

    const formatPrice = (price) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(price);
    };

    const handleBookNow = () => {
        setShowForm(true);
    };

    const country_codes = [
        "+234", "+93", "+355", "+213", "+1-684", "+376", "+244", "+1-264", "+672", "+1-268",
        "+54", "+374", "+297", "+61", "+43", "+994", "+1-242", "+973", "+880", "+1-246",
        "+375", "+32", "+501", "+229", "+1-441", "+975", "+591", "+387", "+267", "+55",
        "+246", "+673", "+359", "+226", "+257", "+855", "+237", "+1", "+238", "+1-345",
        "+236", "+235", "+56", "+86", "+61", "+57", "+269", "+682", "+506",
        "+385", "+53", "+599", "+357", "+420", "+243", "+45", "+253", "+1-767",
        "+1-809", "+1-829", "+1-849", "+670", "+593", "+20", "+503", "+240", "+291",
        "+372", "+268", "+251", "+500", "+298", "+679", "+358", "+33", "+689", "+241",
        "+220", "+995", "+49", "+233", "+350", "+30", "+299", "+1-473", "+1-671",
        "+502", "+44-1481", "+224", "+245", "+592", "+509", "+504", "+852", "+36",
        "+354", "+91", "+62", "+98", "+964", "+353", "+44-1624", "+972", "+39",
        "+225", "+1-876", "+81", "+44-1534", "+962", "+7", "+254", "+686", "+383",
        "+965", "+996", "+856", "+371", "+961", "+266", "+231", "+218", "+423",
        "+370", "+352", "+853", "+261", "+265", "+60", "+960", "+223", "+356",
        "+692", "+222", "+230", "+262", "+52", "+691", "+373", "+377", "+976",
        "+382", "+1-664", "+212", "+258", "+95", "+264", "+674", "+977", "+31",
        "+687", "+64", "+505", "+227", "+234", "+683", "+672", "+850", "+389",
        "+1-670", "+47", "+968", "+92", "+680", "+970", "+507", "+675", "+595",
        "+51", "+63", "+64", "+48", "+351", "+1-787", "+1-939", "+974", "+242",
        "+262", "+40", "+7", "+250", "+590", "+290", "+1-869", "+1-758"
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        const selectedDate = new Date(formData.checkInDate);
        const today = new Date();

        if (selectedDate < today) {
            return toast.error('Check in date cannot be in the past');
        }

        const templateParams = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            check_in_date: formData.checkIn,
            check_out_date: formData.checkOut,
            apartment_choice: formData.apartmentName,
            number_of_nights: formData.getDays,
            amount: formData.amount * formData.getDays
        };

        console.log(templateParams)
        const saveFormDataToLocalStorage = () => {
            localStorage.setItem('formData', JSON.stringify(templateParams));
        };
    
        // saveFormDataToLocalStorage();

        emailjs.send('service_6zb2qrp', 'template_am6y2ya', templateParams, '_AWyO7mQxGs5uQBO6')
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
                saveFormDataToLocalStorage();
                naviagte(`/summary/apartment/${apartment._id}`);
            }, error => {
                console.log('FAILED...', error);
            });
    }

   

    return (
        <div ref={infoPage}>
            <div className="flex flex-col items-center justify-center p-4">
                <div className="grid gap-4">
                    {/* Main Image */}
                    <div>
                        <img
                            className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
                            src={mainImage}
                            alt="main-gallery-image"
                        />
                    </div>

                    {/* Thumbnails */}
                    <div className="flex flex-wrap gap-4">
                        {apartment.images.map((image, index) => (
                            <div className="flex-1 min-w-[100px]" key={index}>
                                <img
                                    src={image}
                                    className="object-cover object-center h-20 max-w-full rounded-lg cursor-pointer"
                                    alt={`gallery-thumbnail-${index}`}
                                    onClick={() => handleThumbnailClick(image)}  // Update the main image on click
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <p className="mt-2 text-2xl font-bold">{apartment.name}</p>
                <p className="text-lg font-bold">{formatPrice(apartment.price)}</p>
                <div className="flex flex-col items-center justify-center p-4">
                    <p className="text-2xl font-bold">Description</p>
                    <p className="text-base font-bold">{apartment.description}</p>
                    <div className="bg-[#cba135] px-4 py-2 rounded-md text-white">
                        <button onClick={handleBookNow}>Book Now</button>
                    </div>
                </div>
            </div>
            {showForm && (
                <div className='flex items-center justify-center'>
                    {/* Display your booking form here */}
                    <div className='p-4'>
                        <div className='flex items-center space-x-4 mb-2'>
                            <h1>Please Fill Your Details </h1>
                        </div>
                        <form onSubmit={handleSubmit} className="w-full max-w-lg">
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                        First Name
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.firstName}
                                        name='firstName'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                        id="grid-first-name"
                                        type="text"
                                        placeholder="Jane"
                                        required
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                        Last Name
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.lastName}
                                        name='lastName'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-last-name"
                                        type="text"
                                        placeholder="Doe"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                                        E-mail
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.email}
                                        name='email'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                        id="grid-email"
                                        type="email"
                                        placeholder="example@mail.com"
                                        required
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address">
                                        Address
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.address}
                                        name='address'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-address"
                                        type="text"
                                        placeholder="11, John Street, Lagos"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-guests">
                                        Guests
                                    </label>
                                    <div className="relative">
                                        <select
                                            onChange={handleChange}
                                            value={formData.guests}
                                            name='guests'
                                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="grid-guests"
                                        >
                                            <option>Individuals</option>
                                            <option>Filmmakers</option>
                                            <option>Party</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-phone">
                                        Phone Number
                                    </label>
                                    <div className='flex items-center gap-4'>
                                        <select
                                            onChange={handleChange}
                                            value={formData.countryCode}
                                            name='countryCode'
                                            className="block appearance-none w-1/4 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="grid-country-code"
                                        >
                                            {country_codes.map((c, index) => (
                                                <option value={c} key={index}>{c}</option>
                                            ))}
                                        </select>
                                        <input
                                            onChange={handleChange}
                                            value={formData.phone}
                                            name='phone'
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="grid-phone"
                                            type="tel"
                                            placeholder="08090000000"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className='w-full md:w-1/2 px-3'>
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-check-in">
                                        Check in
                                    </label>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        value={formData.checkIn}
                                        name='checkIn'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-check-in"
                                    />
                                </div>
                                <div className='w-full md:w-1/2 px-3'>
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-check-out">
                                        Check out
                                    </label>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        value={formData.checkOut}
                                        name='checkOut'
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-check-out"
                                    />
                                </div>
                            </div>
                            <button type="submit" className="w-full bg-[#CBA135] text-white p-2 rounded hover:bg-[#876B23]">Next</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApartmentInfo;
