import React, { useContext } from 'react'
import Nav from '../components/home/Nav'
import LowerNav from '../components/home/LowerNav'
import Footer from '../components/home/Footer'
import Hamburger from '../components/home/Hamburger'
import ApartmentItem from '../components/home/ApartmentItem'
import { fetchDataWithAxios } from '../config/fetchDataWithAxios';
import { useState, useEffect } from 'react';
import Apartments from '../components/apartments/Apartments'
import { Context } from '../components/AppContext';
import { motion } from 'framer-motion'

const Home = () => {
    const {authUser,pageSetter,page, setPage,loggedIn,apartments, setApartments, fetchApartments} = useContext(Context);
    useEffect(()=>{
        setPage('apartments');
    })

    useEffect(()=>{
        fetchApartments();
    }, [])
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
    <motion.div
    initial={{width : 0}}
    animate={{width : "100%"}}
    exit={{x : window.innerWidth, transition: {duration : 0.1}}}
  >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
            <Apartments apartments={apartments}/>
        </div>
        <Footer />
    </motion.div>
  )
}

export default Home