import React, {useState} from 'react'
import { ClickAwayListener } from '@mui/base';
import ApartmentSearchMobile from './ApartmentSearchMobile';
import GuestSearchMobile from './GuestSearchMobile';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import MobileSearchButton from './MobileSearchButton';

const MobileHeroSearch = ({hamburger, setHamburger}) => {
  const [showApartmentDropDown, setShowApartmentDropDown] = useState(false);
    const [showGuestDropDown, setShowGuestDropDown] = useState(false);
    const [apartmentInputText, setApartmentInputText] = useState('Apartment');
    const [guestInputText, setGuestInputText] = useState('Guests');
    const apartments = [
      { id: "64a18d0d6602ee32cae1e663", name: "One Bed Gold" },
      { id: "64a18fc66602ee32cae1e66f", name: "2 Bed Platinum" },
      { id: "64a18ecc6602ee32cae1e669", name: "2 Bed Luxury" },
      { id: "64a18e206602ee32cae1e666", name: "2 Bed Premium" },
      { id: "64a18f466602ee32cae1e66c", name: "2 Bed Economy" },
      { id: "64a190b36602ee32cae1e672", name: "4 Bed Presidential" },
    ];

     // State for selected apartment
  const [selectedApartment, setSelectedApartment] = useState(null);

    const [guests, setGuests] = useState([
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10
    ]);
    const navigate = useNavigate();
  return (
    <div className="homepage-container">
        <div className="mobile-search flex-row" onClick={()=>setHamburger('open')}>
            <div className=""><i class="fa-solid fa-magnifying-glass"></i></div>
            <div className="">Search for your best apartment</div>
        </div>

        <div className={`hamburger-menu ${hamburger == 'open' ? 'open-hamburger' : 'closed-hamburger'}`}>
          <div className="hamburger-top flex-row width-100">
            <div className="white-text-search">
              Search
            </div>
            <div className={`close-hamburger ${hamburger == 'open' ? 'open' : 'close'}`} onClick={()=> setHamburger('close')}> X </div>
          </div>
          <div className='width-100 padding-10'>
              <div className='width-100'>
              <ul className={`${hamburger == 'open' ? 'open' : 'close'} flex-column gap-30 hamburger-links-list`}>
                  <li className='flex-row gap-10 search-input-item'>
                    <i class="fa-regular fa-calendar-days"></i><span className="ext-sm">Arrival</span><input type="datetime-local" placeholder='Arrival'/>
                  </li>
                  <li className='flex-row gap-10 search-input-item'>
                    <i class="fa-regular fa-calendar-days"></i><span className="ext-sm">Departure</span><input type="datetime-local" placeholder='Depature'/>
                  </li>
                  <ApartmentSearchMobile selectedApartment={selectedApartment}
        setSelectedApartment={setSelectedApartment} showApartmentDropDown={showApartmentDropDown} setShowApartmentDropDown={setShowApartmentDropDown} apartmentInputText={apartmentInputText} setApartmentInputText={setApartmentInputText} apartments={apartments}/>
                  <GuestSearchMobile showGuestDropDown={showGuestDropDown} setShowGuestDropDown={setShowGuestDropDown} guestInputText={guestInputText} guests={guests} setGuestInputText={setGuestInputText}/>

                  {/* <li className='flex-column gap-10 center'onClick={() => navigate('/apartments')}><a href="#" className='hamburger-button sm button brown'>Search</a></li> */}
              </ul>
              <MobileSearchButton selectedApartment={selectedApartment}/>

              </div>
          </div>
        </div>
    </div>
  )
}

export default MobileHeroSearch