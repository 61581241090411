import { Navigate } from 'react-router-dom';

const PaymentSuccessRoute = ({ children }) => {
    const paymentSuccess = localStorage.getItem('paymentSuccess');

    if (paymentSuccess === 'true') {
        // Clear the flag so that the page isn't accessible again
        localStorage.removeItem('paymentSuccess');
        return children;
    } else {
        // If payment was not successful, redirect to home or another page
        return <Navigate to="/" />;
    }
};


export default PaymentSuccessRoute;