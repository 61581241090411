import axios from "axios";

const API_URL = "https://milano-q5fy.onrender.com";

// http://localhost:5000/api/apartments

//Get all apartments
const getAllApartments = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(API_URL + "/api/apartment", config);

    if(response.data) {
        localStorage.setItem("apartments", JSON.stringify(response.data));
    }

    return response.data;
}


const apartmentService = {
    getAllApartments,
};


export default apartmentService;