import { useState } from 'react'
import React from 'react'
import Carousel from './Carousel'
import PhotoGallery from '../photogallery/PhotoGallery'

import front from './2bedluxury/1.jpg'
import front2 from './2bedroomeconomy/eco2.jpg'
import front3 from './4bedroom/a1.jpg'
import front4 from './2bedroompremiumeco/a1.jpg'
import front5 from './gold/gold1.jpg'
import front6 from './platinum/a29.jpg'

import L1 from "./2bedluxury/1.jpg"
import L2 from "./2bedluxury/2.jpg"
import L3 from "./2bedluxury/3.jpg"
import L4 from "./2bedluxury/5.jpg"
import L5 from "./2bedluxury/a48.jpg"
import L6 from "./2bedluxury/a50.jpg"
import L7 from "./2bedluxury/a52.jpg"
import L8 from "./2bedluxury/a55.jpg"
import L9 from "./2bedluxury/a57.jpg"
import L10 from "./2bedluxury/a58.jpg"
import L11 from "./2bedluxury/a62.jpg"
import L12 from "./2bedluxury/a63.JPG"
import L13 from "./2bedluxury/lux 1.jpg"
import L14 from "./2bedluxury/lux 2.jpg"
import L15 from "./2bedluxury/lux 3.jpg"
import L16 from "./2bedluxury/lux 4.jpg"
import L17 from "./2bedluxury/lux 5.jpg"
import L18 from "./2bedluxury/lux 6.jpg"
import L20 from "./2bedluxury/lux 8.jpg"
import L21 from "./2bedluxury/lux 9.jpg"
import L22 from "./2bedluxury/lux 10.jpg"

import P1 from "./4bedroom/a1.jpg"
import P2 from "./4bedroom/a4.jpg"
import P3 from "./4bedroom/a5.jpg"
import P4 from "./4bedroom/a6.jpg"
import P5 from "./4bedroom/a7.jpg"
import P6 from "./4bedroom/a10.jpg"
import P7 from "./4bedroom/a14.jpg"
import P8 from "./4bedroom/a18.jpg"
import P9 from "./4bedroom/d12.jpg"
import P10 from "./4bedroom/d14.jpg"
import P11 from "./4bedroom/e1.jpg"
import P12 from "./4bedroom/e2.jpg"
import P13 from "./4bedroom/e3.jpg"
import P14 from "./4bedroom/pre1.jpg"
import P15 from "./4bedroom/pre2.jpg"
import P16 from "./4bedroom/pre3.jpg"
import P17 from "./4bedroom/pre4.jpg"
import P18 from "./4bedroom/pre5.jpg"
import P20 from "./4bedroom/pre7.jpg"
import P21 from "./4bedroom/pre8.jpg"
import P22 from "./4bedroom/pre9.jpg"
import P23 from "./4bedroom/pre10.jpg"
import P24 from "./4bedroom/pre11.jpg"
import P25 from "./4bedroom/pre12.jpg"
import P26 from "./4bedroom/pre13.jpg"

import PE1 from "./2bedroompremiumeco/a1.jpg"
import PE2 from "./2bedroompremiumeco/a9.jpg"
import PE3 from "./2bedroompremiumeco/a64.jpg"
import PE4 from "./2bedroompremiumeco/a69.jpg"
import PE5 from "./2bedroompremiumeco/a70.jpg"
import PE6 from "./2bedroompremiumeco/a71.jpg"
import PE7 from "./2bedroompremiumeco/a73.jpg"
import PE8 from "./2bedroompremiumeco/a74.jpg"
import PE9 from "./2bedroompremiumeco/b1.jpg"
import PE10 from "./2bedroompremiumeco/b3.jpg"
import PE11 from "./2bedroompremiumeco/b4.jpg"
import PE12 from "./2bedroompremiumeco/b5.jpg"
import PE13 from "./2bedroompremiumeco/b6.jpg"
import PE14 from "./2bedroompremiumeco/b7.jpg"
import PE17 from "./2bedroompremiumeco/b10.jpg"
import PE19 from "./2bedroompremiumeco/f8.jpg"

import E1 from "./2bedroomeconomy/eco1.jpg"
import E2 from "./2bedroomeconomy/eco2.jpg"
import E3 from "./2bedroomeconomy/eco3.jpg"
import E4 from "./2bedroomeconomy/eco4.jpg"
import E5 from "./2bedroomeconomy/eco5.jpg"

import PL1 from "./platinum/a29.jpg"
import PL2 from "./platinum/a30.jpg"
import PL4 from "./platinum/a33.jpg"
import PL5 from "./platinum/a34.jpg"
import PL6 from "./platinum/a35.jpg"
import PL7 from "./platinum/c6.jpg"
import PL8 from "./platinum/pla1.jpg"
import PL9 from "./platinum/pla2.jpg"
import PL11 from "./platinum/pla4.jpg"

import G1 from "./gold/a35.jpg"
import G2 from "./gold/a38.jpg"
import G3 from "./gold/gold1.jpg"
import G4 from "./gold/gold3.jpg"
import G10 from "./gold/gold4.jpg"
import G11 from "./gold/gold5.jpg"


const Luxury = () => {
  const images = [
    L1,
    L2,
    L3,
    L4,
    L5,
    L6,
    L7,
    L8,
    L9,
    L10,
    L11,
    L12,
    L13,
    L14,
    L15,
    L16,
    L17,
    L18,
    L20,
    L21,
    L22,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}
const Presidential = () => {
  const images = [
    P1,
    P2,
    P3,
    P4,
    P5,
    P6,
    P7,
    P8,
    P9,
    P10,
    P11,
    P12,
    P13,
    P14,
    P15,
    P16,
    P17,
    P18,
    P20,
    P21,
    P22,
    P23,
    P24,
    P25,
    P26,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}
const Premium = () => {
  const images = [
    PE1,
    PE2,
    PE3,
    PE4,
    PE5,
    PE6,
    PE7,
    PE8,
    PE9,
    PE10,
    PE11,
    PE12,
    PE13,
    PE14,
    PE17,
    PE19,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}
const Economy = () => {
  const images = [
    E1,
    E2,
    E3,
    E4,
    E5,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}
const Platinum = () => {
  const images = [
    PL1,
    PL2,
    PL4,
    PL5,
    PL6,
    PL7,
    PL8,
    PL9,
    PL11,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}
const Gold = () => {
  const images = [
    G1,
    G2,
    G3,
    G4,
    G10,
    G11,
  ];

  return (
    <div className=''>
      <Carousel images={images} />
    </div>
  )
}



const DesktopGallery = () => {
  const [showGallery, setShowGallery] = useState(null)

  const handleSeeMoreClick = (component) => {
    setShowGallery(component);
  };

  return (
    <div className='grid md:grid-cols-3 gap-6'>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Luxury Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Luxury />)}>See More Photos</button>
        </div>
      </div>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Presidential Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Presidential />)}>See More Photos</button>
        </div>
      </div>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front4})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Premium Economy Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Premium />)}>See More Photos</button>
        </div>
      </div>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Economy Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Economy />)}>See More Photos</button>
        </div>
      </div>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front6})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Platinum Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Platinum />)}>See More Photos</button>
        </div>
      </div>
      <div class="relative grid h-[30rem] w-full max-w-[28rem] flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center text-gray-700">
        <div class="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent" style={{ backgroundImage: `url(${front5})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
        </div>
        <div class="relative p-6 px-6 py-14 md:px-12">
          <h2 class="mb-6 block font-sans text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
            Gold Apartment
          </h2>
          <button className="bg-[#CBA135] text-white font-bold py-2 px-4 rounded" onClick={() => handleSeeMoreClick(<Gold />)}>See More Photos</button>
        </div>
      </div>

      {showGallery && (
        <div className='fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-90 flex justify-center items-center z-50'>
          {showGallery}
          <button className='absolute top-[20%] md:top-2 right-4 md:right-2 text-white text-4xl md:text-2xl' onClick={() => setShowGallery(null)}>X</button>
        </div>

      )}
    </div>
  )
}

export default DesktopGallery