import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import Hamburger from '../components/home/Hamburger';
import LowerNav from '../components/home/LowerNav';
import Footer from '../components/home/Footer';
import OrderSummary from '../components/apartment/OrderSummary';

const Summary = ({ item, formData }) => {

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Hamburger />
      <LowerNav />
      <div className='max-w-6xl mx-auto mt-10  dark:bg-gray-800'>
        <OrderSummary item={item} formData={formData}/>
      </div>
      <Footer />
    </motion.div>
  )
}

export default Summary