import React from 'react'
import { useNavigate } from 'react-router-dom';

const DesktopFooter = () => {
    const navigate = useNavigate();
    return (
        <div className="flex-row  flex-space-between grid-four desktop">
            <div className="flex-column justify-space-between center">
                <div className="logo-footer align-flex-start cursor" onClick={() => navigate('/')}>
                    <img src="/images/logo-footer.svg" alt="" />
                </div>
                <div className="barcode-container flex-column center">
                    <div className=""><img src="/images/Price.png" alt="" /></div>
                    <div className="footer-text scan flex-row center">Scan our Bar Code</div>
                </div>
            </div>

            <div className="flex-column gap-20">
                <div className="footer-header">Quicklinks</div>
                <div className="flex-column gap-10">
                    <div className="footer-text cursor"><a href='/apartments'>Apartments</a></div>
                    <div className="footer-text cursor"><a href='/ride'>Chauffeur</a></div>
                    <div className="footer-text cursor"><a href='/gallery'>Gallery</a></div>
                    <div className="footer-text cursor"><a href='/food'>Catering</a></div>
                    <div className="footer-text cursor"><a href='/laundry'>Laundry</a></div>
                    <div className="footer-text cursor"><a href='/bar'>Mini Lounge</a></div>
                </div>
            </div>
            <div className="flex-column gap-20">
                <div className="footer-header">Terms & Policies</div>
                <div className="flex-column gap-10">
                    <div className="footer-text cursor"><a href='/terms'>Terms & Conditions</a></div>
                </div>
            </div>
            <div className="flex-row justify-space-between gap-40 grid-footer">
                <div className="flex-column justify-space-between align-self-flex-start">
                    <div className="flex-column gap-20">
                        <div className="footer-header cursor" onClick={() => navigate('/contact')}>Contact Us</div>
                        <div className="footer-text">Reach out to us on our social media platforms.</div>
                        <div className="flex-row gap-10 orange">
                            <div className="footer-icon cursor"><a href='https://instagram.com/milanosignatureint?igshid=MzRlODBiNWFlZA==' target='_blank'><i class="fa-brands fa-instagram"></i></a></div>
                            <div className="footer-icon cursor"><a href='https://www.facebook.com/profile.php?id=100078211172407&mibextid=ZbWKwL' target='_blank'><i class="fa-brands fa-facebook"></i></a></div>
                            <div className="footer-icon cursor"><a href='https://youtube.com/@milanosignature511' target='_blank'><i class="fa-brands fa-youtube"></i></a></div>
                            <div className="footer-icon cursor"><a href='https://www.linkedin.com/in/milano-signature-312a80234' target='_blank'><i class="fa-brands fa-linkedin"></i></a></div>
                        </div>
                    </div>
                    <div className="button white-button footer-button sm-long">
                        <a href="/files/profile.pdf" style={{ width: '100px' }} target='_blank' >
                            Download our Profile
                        </a>
                    </div>
                    <div className="button white-button footer-button ">
                        <a href="/files/attraction.pdf" style={{ width: '100px' }} target='_blank' >
                            View our Attraction Sites
                        </a>
                    </div>
                </div>
                <div className="flex-row center footer-arrow" onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }}><i class="fa-solid fa-arrow-up"></i></div>
            </div>
        </div>
    )
}

export default DesktopFooter