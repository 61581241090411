import React from 'react'
import { useContext } from 'react';
import Hero from './Hero';
import Nav from './Nav';
import { Context } from '../AppContext';

function HeroNew() {
    const { authUser, pageSetter, loggedIn, page, setPage, apartments, setApartments, fetchApartments, setLoggedIn } = useContext(Context);
    return (
        <div>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage} setLoggedIn={setLoggedIn} /> */}
            <Hero loggedIn={loggedIn} page={page} setPage={setPage} />
        </div>
    )
}

export default HeroNew