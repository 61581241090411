import React, { useContext, useEffect } from 'react'
import Nav from '../components/home/Nav'
import LowerNav from '../components/home/LowerNav'
import Hero from '../components/home/Hero'
import Services from '../components/home/Services'
import Apartments from '../components/home/Apartments'
import Instagram from '../components/home/Instagram'
import Reviews from '../components/home/Reviews'
import NewsPaper from '../components/home/NewsLetter'
import Footer from '../components/home/Footer'
import Hamburger from '../components/home/Hamburger'
import { Context } from '../components/AppContext';
import { motion } from 'framer-motion'
import HeroNew from '../components/home/HeroNew'
import AOS from 'aos';
import 'aos/dist/aos.css';
import PhotoGallery from '../components/photogallery/PhotoGallery'
import { Link } from 'react-router-dom'
import About from '../components/about/About'

const Home = () => {
  const { authUser, pageSetter, loggedIn, page, setPage, apartments, setApartments, fetchApartments, setLoggedIn } = useContext(Context);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease', // Animation easing
    });
  }, []);
  const images = [
    "/images/gallery-one.jpg",
    "/images/gallery-two.jpg",
    "/images/gallery-three.jpg",
    "/images/gallery-four.jpg",
    "/images/gallery-five.jpg",
    "/images/gallery-six.jpg",
    // Add more images as needed
  ];
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Hamburger loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage} setLoggedIn={setLoggedIn} />
      <HeroNew />
      
      <div className='homepage-container'>
        {/* <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}

        {/* <Apartments apartments={apartments} setApartments={setApartments} fetchApartments={fetchApartments} /> */}
        {/* <Instagram /> */}
      </div>
      <Services />
      <div className='bg-gray-200'>
        {/* <PhotoGallery images={images} /> */}
        <About />
      </div>
      
      <Reviews />
      <NewsPaper />
      <Footer />
    </motion.div>
  )
}

export default Home