import axios from "axios";
import { baseUrl } from "./baseUrl";
import { cyclicUrl } from "./baseUrl";

export const sendDataWithAxios = async (endpoint, body= {}, token = '', url = "cyclic") => {
  let baseurl = url == "render" ? baseUrl : cyclicUrl; 
  let response = await axios({
    method: "post",
    url: baseurl + endpoint,
    data: body,
    headers: { 
      "Content-Type": "application/json", 
      Accept: "application/json",
      Authorization: `Bearer ${token}`
     },
  });
  return response;
};
