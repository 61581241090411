import React from 'react'
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate();

    const handleBookNowClick = () => {
        navigate('/apartments');
    }

    return (
        <div className="flex flex-col md:flex-row items-center justify-center p-4">
            <div className="md:w-1/2 p-4">
                <h2 className="text-2xl font-bold mb-4">What We Offer</h2>
                <p className="text-gray-700">
                    Welcome to the Milano Signatures Apartment. We sincerely hope that your expectations are entirely fulfilled and that your stay is unforgettable. We elevate the status of hospitality services and converts a one-time visitors to returning customers, Thank you for visiting us!
                </p>
                <button className="button brown mt-4" onClick={handleBookNowClick}>
                    Book Now
                </button>
            </div>
            <div className="md:w-1/2 p-4">
                <video className="w-full h-auto" controls>
                    <source src="/videos/Milano.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export default About