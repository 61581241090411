import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import apartmentReducer from "../features/apartments/apartmentSlice";
import reviewReducer from "../features/reviews/reviewSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        apartments: apartmentReducer,
        review: reviewReducer,
    },
});
