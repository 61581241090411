import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reviewService from "./reviewService";

// Get apartments from local storage
const review = JSON.parse(localStorage.getItem('review'));

const initialState = {
    review: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Get all apartments
export const getReview = createAsyncThunk(
    'review/getAll',
    async (_, thunkAPI) => {
        try {;
            return await reviewService.getReview();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReview.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReview.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.review = action.payload;
            })
            .addCase(getReview.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    }
});


export const { reset } = reviewSlice.actions;
export default reviewSlice.reducer;