import React from 'react'

const ArrivalInput = ({arrivalInputType, setArrivalInputType, arrivalInput, setArrivalInput}) => {
  return (
    <div className='item search-nav-flex' id='two'>
        <div className='flex-row center gap-10 input-date-search'>
            <div className='item-flex'><i class="fa-regular fa-calendar-days"></i></div>
            <input type={arrivalInputType == 'text' ? 'text' : 'date'} placeholder='Arrival' onFocus={()=>setArrivalInputType('date')} onBlur={()=>setArrivalInputType('text')} value={arrivalInput} onChange={(e)=>setArrivalInput(e.currentTarget.value)}/>

        </div>
        <div className='item-flex'> <div className='thin-vertical'></div></div>
    </div>
  )
}

export default ArrivalInput