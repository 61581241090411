import React, { useEffect } from 'react';
import ApartmentItem from '../home/ApartmentItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApartments } from '../../features/apartments/apartmentSlice';
import Spinner from '../login/Spinner';

const Apartments = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { apartments, isLoading, isSuccess, isError } = useSelector((state) => state.apartments);

    useEffect(() => {
        dispatch(getAllApartments());
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <svg
                    className={`animate-spin w-16 h-16 text-[#876B23]`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle className={`stroke-current stroke-2`} cx="12" cy="12" r="10" stroke="currentColor" />
                </svg>
                    <p>Loading .....</p>
            </div>
        );
    }

    return (
        <div className='apartments-container flex-column center apartments-page'>
            {/* <div className="section-text">Available Listings.</div> */}
            <div className="apartments-grid">
                {apartments && apartments?.map((item, index) => (
                    <ApartmentItem key={index} item={item} />
                ))}
            </div>
        </div>
    );
};

export default Apartments;
