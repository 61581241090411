import React, { useContext, useEffect } from 'react'
import { Context } from '../components/AppContext';
import Nav from '../components/home/Nav';
import LowerNav from '../components/home/LowerNav';
import Hamburger from '../components/home/Hamburger';
import { motion } from 'framer-motion'


const Food = () => {
    const {authUser,pageSetter,loggedIn,page,setPage,apartments, setApartments,fetchApartments} = useContext(Context);
    useEffect(()=>{
        setPage('food');
    })
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
        </div>

        <div className='laundry-page-container food ride-container gap-20  margin-120'>
            <div className="big">
            Enjoy Mouth-Watering Dishes at Milano Signatures Apartment
            </div>
            <div className="sm">
             We have the best chefs to give you any meal of your choice.
            </div>
            <div className="button brown food"  onClick={() => {
    window.open('https://www.thesoupbay.com/', '_blank');
  }}> Order now</div>
        </div>

        {/* <div className='food-page-container food-container   margin-120'>
            <div className="text-container flex-column gap-20">
                <div className="big">
                Enjoy Mouth-watering Dishes at Milano Signatures Apartment
                </div>
                <div className="sm">
                    At Milano Signatures, We have the best chefs to give you any meal of your choice.
                </div>
                <div className="button brown food" onClick={()=>{
                    window.location.assign('https://www.thesoupbay.com/');
                }}> Order now</div>
            </div>
            <div className="img">
                <img src="/images/food-img.png" alt="not found" />
            </div>
        </div> */}

    </motion.div>
      )
}

export default Food