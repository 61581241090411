import React from 'react';
import { ClickAwayListener } from '@mui/base';

const ApartmentSearch = ({
  showApartmentDropDown,
  setShowApartmentDropDown,
  apartmentInputText,
  setApartmentInputText,
  apartments,
  selectedApartment,
  setSelectedApartment,
}) => {
  return (
    <ClickAwayListener onClickAway={() => setShowApartmentDropDown(false)} mouseEvent={'onMouseDown'}>
      <div className='apartment-container' id='four'>
        <div className="flex-row center gap-10 apartment-text">
          <div
            className='flex-row center gap-10'
            onClick={() => {
              setShowApartmentDropDown(!showApartmentDropDown);
            }}
          >
            <div className='item-flex'><i className="fa-solid fa-person-shelter"></i></div>
            <input type="text" placeholder={apartmentInputText} className='disabled' />
            {!showApartmentDropDown && <i className="fa-solid fa-angle-down"></i>}
            {showApartmentDropDown && <i className="fa-solid fa-angle-up"></i>}
            <div className='item-flex'> <div className='thin-vertical'></div></div>
          </div>
        </div>
        <div className={`apartment-menu-overlay flex-column center gap-10 ${showApartmentDropDown ? "visible" : "hidden"}`}>
          {apartments.map((item, index) => {
            const { id, name } = item; // Destructure the unique ID and name from the apartment object

            return (
              <div
                key={id} // Use the unique ID as the key
                className={`cursor ${selectedApartment === item ? "selected" : ""}`}
                onClick={() => {
                  setApartmentInputText(name); // Use the name from the apartment object
                  setSelectedApartment(item); // Set the selected apartment with the name and unique ID
                }}
              >
                {name}
              </div>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default ApartmentSearch;
