import React, { useContext, useEffect } from 'react'
import { Context } from '../components/AppContext';
import { motion } from 'framer-motion'
import Hamburger from '../components/home/Hamburger'
import AOS from 'aos';
import HeroNew from '../components/home/HeroNew';
import Nav from '../components/home/Nav';
import RoomRentalAgreementForm from '../components/KYC/RoomRentalAgreementForm';
import Footer from '../components/home/Footer';

const Kyc = () => {
    const { authUser, pageSetter, loggedIn, page, setPage, apartments, setApartments, fetchApartments, setLoggedIn } = useContext(Context);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            easing: 'ease', // Animation easing
        });
    }, []);

    return (
        <motion.div
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
        >
            <Hamburger loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage} setLoggedIn={setLoggedIn} />
            <Nav background="" />
            <div className='mt-48 mb-24'>
                <RoomRentalAgreementForm />
            </div>

            <Footer />

        </motion.div>
    )
}

export default Kyc