import React from 'react'
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiper } from 'swiper/react';
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import { fetchDataWithAxios } from '../../config/fetchDataWithAxios';
import { useState, useEffect } from 'react';
import ReviewItem from './ReviewItem';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getReview } from '../../features/reviews/reviewSlice';

const Reviews = () => {
    const demoReviews = [
        {
            "_id": "646b92389a17d6a4330ae560",
            "firstName": "Daniel",
            "lastName": "James",
            "occupation": "Medical Doctor",
            "star": 5,
            "image": "https://res.cloudinary.com/damltcdhl/image/upload/v1684771606/px0jjsashgifxahf9ry3.png",
            "approved": true,
            "review": "I got an apartment here for my 26th Birthday. My friends loved the place. Their facilities are top notch. They also offered us excellent customer service",
            "createdAt": "2023-05-22T16:03:04.262Z",
            "updatedAt": "2023-05-22T16:03:04.262Z",
            "__v": 0
        },
        {
            "_id": "646b93489a17d6a4330ae562",
            "firstName": "Damilola",
            "lastName": "Ajibade",
            "occupation": "Web developer",
            "star": 5,
            "image": "https://res.cloudinary.com/damltcdhl/image/upload/v1684771877/lpy9cyx7jx1cm3md35zx.png",
            "approved": true,
            "review": "After coming into lagos for a short work trip, I contacted Miliano’s Apartments and they hooked me up with a nice 2 bedroom apartment. Facility is top notch.",
            "createdAt": "2023-05-22T16:07:36.173Z",
            "updatedAt": "2023-05-22T16:07:36.173Z",
            "__v": 0
        },
        {
            "_id": "646b93ff9a17d6a4330ae564",
            "firstName": "Adegoke",
            "lastName": "Bestman",
            "occupation": "Backend developer",
            "star": 5,
            "image": "https://res.cloudinary.com/damltcdhl/image/upload/v1684772061/oiz23xixk5dycrdmsfml.png",
            "approved": true,
            "review": "I came back from Canada to stay in Lagos for 2 weeks. I stayed at Miliano’s Apartment. I can tell you for sure that they are excellent at their job. Customer service 100%",
            "createdAt": "2023-05-22T16:10:39.596Z",
            "updatedAt": "2023-05-22T16:10:39.596Z",
            "__v": 0
        },
        {
            "_id": "646b945b9a17d6a4330ae566",
            "firstName": "Onyeka",
            "lastName": "Kingsley",
            "occupation": "UI/UX designer",
            "star": 5,
            "image": "https://res.cloudinary.com/damltcdhl/image/upload/v1684772153/ftswjhfom4qkebi7u8o5.png",
            "approved": true,
            "review": "Miliano’s Apartment offered me a good apartment. It was nothing short of comfort and luxury. I recommend them anytime anyday.",
            "createdAt": "2023-05-22T16:12:11.681Z",
            "updatedAt": "2023-05-22T16:12:11.681Z",
            "__v": 0
        },
        {
            "_id": "646b948c9a17d6a4330ae568",
            "firstName": "Davio",
            "lastName": "White",
            "occupation": "Brand Identity Designer",
            "star": 5,
            "image": "https://res.cloudinary.com/damltcdhl/image/upload/v1684772202/wph3ozvxazphbrbopoy6.png",
            "approved": true,
            "review": "Miliano’s Apartment is one of the best shortllet Apartment in Magodo. I experienced their services firsthand.",
            "createdAt": "2023-05-22T16:13:00.006Z",
            "updatedAt": "2023-05-22T16:13:00.006Z",
            "__v": 0
        },
        {
            "_id": "647146fb69bdbe0a90cb5f02",
            "firstName": "Hello",
            "lastName": "Hiii",
            "occupation": "Engineer",
            "star": 5,
            "image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAMFBMVEXk5ueutLfn6eq4vb+rsbTP0tSorrLGysy0ubzc3+Dg4uPX2tu7wMPS1dfBxcjq7O2mCm8BAAADC0lEQVR4nO2aadbqIAxAIcy0pfvf7aN1OPo9LUlN0B/cDXhPJihRqcFgMBgMBoPBYDAY3IHKN39d5bS4ypLUV0QAphK19XZHm5DWzhqQQ/15/YD10amOFpCLfRK4auh+FuD0C4MNH1MfCZj9a4PdIqwdDPKrNDxIzOKRgHRosFWFEVdoGOwSspHIbYUqMUvWBMRWJnZ8kIsEBJRCjcQkJQHTQVM+E4UUlIpYBW2FsgEOmYldQkRBAd5AKhCkMNTeEFCofUlR0NYJBCKjm+KCwMgmpqIGIvE7GJqCRDJQJ8UThd2heWb/R+R2AEcsyZqMzO2APa4eHCZuh0JVEHCY6Q4Ls8NKbU2B5vwFhzO5YHc4UZPc9QDlB3qTemQJzCg1kR00t8KJM0vgw5N2jRK5UUKgOnCXZCVRD05+BdrVXuhyTzy9PXtn7pAcZB4hSKPS89+qdwgjQuwZhDKvhcJA+MaQfIhRuBlhJd8HYcFlQ85AIUvCCj8Yr+1vHWmF9geX1Vn+2RyWd6/2u0Lssj6AbN6GQrQpn1hdfGVhrckdNgdXQDn9d4VgtRF7nX1jAVO5bNP2AFivQ/rCbm+F5MJsjJlLWHLvrd4VWGG90T0GsK9XJxdCmTdKCW6ZcredL6wquWJu29VbR1jvrY4lTGqVzQpAnkLcfv3tiKrFWZYsFY4a/mXWRzPy7qGjSwLjsvbirD36HuWt4d75ggqa+onjbWGcmpAKPgIPWM81OSEXRBG8sbCG4zJRP6/OGlw05k/rAqbPDDYJ7z5pVVD0V6hXFh8kBBJurduW0GefCRHXVzS+nIsCSx5unLpnwsypsOWDLAGGV2GzoF76+RWoEtyJuEF4HzqxOEJh8X9JgIWvKf9IYPeN7b/+fCCBHFYCLfEAqiRObAkIWIO514BUMVzwiGuNVE/cwayghRW0X1oSstWw035Fpq5K6DT/nHFibUV2aLzV8F4a3tCoSuKi5ByNZOQOYWgNbORb8IcOhyeX+IC6EI/mdZeSrIE4doimA8eNAX04UhgMBoPBL/IPJ+4illzek3MAAAAASUVORK5CYII=",
            "approved": false,
            "review": "hello",
            "createdAt": "2023-05-26T23:55:39.123Z",
            "updatedAt": "2023-05-26T23:55:39.123Z",
            "__v": 0
        },
        {
            "_id": "6471473e69bdbe0a90cb5f08",
            "firstName": "Hello",
            "lastName": "Hiii",
            "occupation": "Engineer",
            "star": 5,
            "image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAMFBMVEXk5ueutLfn6eq4vb+rsbTP0tSorrLGysy0ubzc3+Dg4uPX2tu7wMPS1dfBxcjq7O2mCm8BAAADC0lEQVR4nO2aadbqIAxAIcy0pfvf7aN1OPo9LUlN0B/cDXhPJihRqcFgMBgMBoPBYDAY3IHKN39d5bS4ypLUV0QAphK19XZHm5DWzhqQQ/15/YD10amOFpCLfRK4auh+FuD0C4MNH1MfCZj9a4PdIqwdDPKrNDxIzOKRgHRosFWFEVdoGOwSspHIbYUqMUvWBMRWJnZ8kIsEBJRCjcQkJQHTQVM+E4UUlIpYBW2FsgEOmYldQkRBAd5AKhCkMNTeEFCofUlR0NYJBCKjm+KCwMgmpqIGIvE7GJqCRDJQJ8UThd2heWb/R+R2AEcsyZqMzO2APa4eHCZuh0JVEHCY6Q4Ls8NKbU2B5vwFhzO5YHc4UZPc9QDlB3qTemQJzCg1kR00t8KJM0vgw5N2jRK5UUKgOnCXZCVRD05+BdrVXuhyTzy9PXtn7pAcZB4hSKPS89+qdwgjQuwZhDKvhcJA+MaQfIhRuBlhJd8HYcFlQ85AIUvCCj8Yr+1vHWmF9geX1Vn+2RyWd6/2u0Lssj6AbN6GQrQpn1hdfGVhrckdNgdXQDn9d4VgtRF7nX1jAVO5bNP2AFivQ/rCbm+F5MJsjJlLWHLvrd4VWGG90T0GsK9XJxdCmTdKCW6ZcredL6wquWJu29VbR1jvrY4lTGqVzQpAnkLcfv3tiKrFWZYsFY4a/mXWRzPy7qGjSwLjsvbirD36HuWt4d75ggqa+onjbWGcmpAKPgIPWM81OSEXRBG8sbCG4zJRP6/OGlw05k/rAqbPDDYJ7z5pVVD0V6hXFh8kBBJurduW0GefCRHXVzS+nIsCSx5unLpnwsypsOWDLAGGV2GzoF76+RWoEtyJuEF4HzqxOEJh8X9JgIWvKf9IYPeN7b/+fCCBHFYCLfEAqiRObAkIWIO514BUMVzwiGuNVE/cwayghRW0X1oSstWw035Fpq5K6DT/nHFibUV2aLzV8F4a3tCoSuKi5ByNZOQOYWgNbORb8IcOhyeX+IC6EI/mdZeSrIE4doimA8eNAX04UhgMBoPBL/IPJ+4illzek3MAAAAASUVORK5CYII=",
            "approved": false,
            "review": "Hello",
            "createdAt": "2023-05-26T23:56:46.040Z",
            "updatedAt": "2023-05-26T23:56:46.040Z",
            "__v": 0
        },
        {
            "_id": "6471477869bdbe0a90cb5f0a",
            "firstName": "Hello",
            "lastName": "Hiii",
            "occupation": "Engineer",
            "star": 5,
            "image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAMFBMVEXk5ueutLfn6eq4vb+rsbTP0tSorrLGysy0ubzc3+Dg4uPX2tu7wMPS1dfBxcjq7O2mCm8BAAADC0lEQVR4nO2aadbqIAxAIcy0pfvf7aN1OPo9LUlN0B/cDXhPJihRqcFgMBgMBoPBYDAY3IHKN39d5bS4ypLUV0QAphK19XZHm5DWzhqQQ/15/YD10amOFpCLfRK4auh+FuD0C4MNH1MfCZj9a4PdIqwdDPKrNDxIzOKRgHRosFWFEVdoGOwSspHIbYUqMUvWBMRWJnZ8kIsEBJRCjcQkJQHTQVM+E4UUlIpYBW2FsgEOmYldQkRBAd5AKhCkMNTeEFCofUlR0NYJBCKjm+KCwMgmpqIGIvE7GJqCRDJQJ8UThd2heWb/R+R2AEcsyZqMzO2APa4eHCZuh0JVEHCY6Q4Ls8NKbU2B5vwFhzO5YHc4UZPc9QDlB3qTemQJzCg1kR00t8KJM0vgw5N2jRK5UUKgOnCXZCVRD05+BdrVXuhyTzy9PXtn7pAcZB4hSKPS89+qdwgjQuwZhDKvhcJA+MaQfIhRuBlhJd8HYcFlQ85AIUvCCj8Yr+1vHWmF9geX1Vn+2RyWd6/2u0Lssj6AbN6GQrQpn1hdfGVhrckdNgdXQDn9d4VgtRF7nX1jAVO5bNP2AFivQ/rCbm+F5MJsjJlLWHLvrd4VWGG90T0GsK9XJxdCmTdKCW6ZcredL6wquWJu29VbR1jvrY4lTGqVzQpAnkLcfv3tiKrFWZYsFY4a/mXWRzPy7qGjSwLjsvbirD36HuWt4d75ggqa+onjbWGcmpAKPgIPWM81OSEXRBG8sbCG4zJRP6/OGlw05k/rAqbPDDYJ7z5pVVD0V6hXFh8kBBJurduW0GefCRHXVzS+nIsCSx5unLpnwsypsOWDLAGGV2GzoF76+RWoEtyJuEF4HzqxOEJh8X9JgIWvKf9IYPeN7b/+fCCBHFYCLfEAqiRObAkIWIO514BUMVzwiGuNVE/cwayghRW0X1oSstWw035Fpq5K6DT/nHFibUV2aLzV8F4a3tCoSuKi5ByNZOQOYWgNbORb8IcOhyeX+IC6EI/mdZeSrIE4doimA8eNAX04UhgMBoPBL/IPJ+4illzek3MAAAAASUVORK5CYII=",
            "approved": false,
            "review": "Hello",
            "createdAt": "2023-05-26T23:57:44.539Z",
            "updatedAt": "2023-05-26T23:57:44.539Z",
            "__v": 0
        }
    ]
    // const [reviews, setReviews] = useState([]);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { review, isLoading, isSuccess, isError } = useSelector((state) => state.review);
    useEffect(() => {
        dispatch(getReview());
    }, [])

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <svg
                    className={`animate-spin w-16 h-16 text-[#876B23]`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle className={`stroke-current stroke-2`} cx="12" cy="12" r="10" stroke="currentColor" />
                </svg>
                <p>Loading .....</p>
            </div>
        );
    }
    return (
        <div className='reviews-container'>
            <div className='section-text'>Testimonials</div>
            <ReviewItem review={review} />
            <div className="button cta-review white-button"><a href='/review'>Leave a review</a></div>
        </div>

    )
}

export default Reviews

