import React from 'react'

const GridThree = () => {
  return (
        <div className="grid one">
          <div className="about-desc flex-column gap-20">
            <div className="title flex-row center">
                Milano Features
            </div>
            <div className="summary flex-column gap-20">
                <span className="about-text">Milano offers a range of exceptional features that will enhance your living experience. These include complimentary parking, a tranquil environment, a well-equipped kitchen, a balcony with a terrace view, air conditioning, a washing machine, and top-notch security measures. Additionally, we provide chauffeur, catering, and laundry services to cater to your needs. Moreover, we have a mini lounge where you can unwind and enjoy yourself.
                </span>
            </div>
          </div>
          <div className="images">
            <div className="">
                <img src="images/hero-image-1.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-2.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-3.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-1.jpg" alt="" />
            </div>
          </div>
        </div>
  )
}

export default GridThree