import React from 'react'

const NewsLetter = () => {
  return (
    <div className='flex-column center newsletter-container'>
        <div className="flex-column center gap-10">
            <div className="section-text">Subscribe to our news letter</div>
            <div className="">Get daily updates from us.</div>
        </div>
        <div className="newsletter-input center gap-20">
            <div className="">
              <input type="text" placeholder='Email Address'/>
            </div>
            <div className="button brown">Subscribe</div>
        </div>
    </div>
  )
}

export default NewsLetter