import React from 'react'
import { ClickAwayListener } from '@mui/base';

const GuestSearchMobile = ({showGuestDropDown, setShowGuestDropDown, guestInputText, guests, setGuestInputText}) => {
  return (
        <ClickAwayListener onClickAway={() => setShowGuestDropDown(false)} mouseEvent={'onMouseDown'}>
            <li className='flex-column search-input-item center position-relative'>
                <div className="flex-row gap-10 center guest-container-mobile" onClick={()=> setShowGuestDropDown(true)}>
                    <div className="flex-row center gap-10">
                        <i class="fa-solid fa-people-line"></i> 
                        <input type="text" placeholder={guestInputText}/>
                    </div>
                    <i class="fa-solid fa-angle-down"></i>
                </div>
                <div className={`apartment-menu-overlay flex-column center guests gap-10 ${showGuestDropDown ? 'visible' : 'hidden'} color-black`}>
                {guests.map((item, index) =>
                    <div key={index} className="cursor" onClick={()=> setGuestInputText(item)}>{item} </div>
                )}
                </div>
            </li>
            </ClickAwayListener>
  )
}

export default GuestSearchMobile