import React from 'react'
import { Autoplay, Pagination, Navigation } from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react'
import { useSwiper } from 'swiper/react';
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
const ReviewItem = ({review}) => {
  return (
    <>
        <div className="flex-row center">          
            <Swiper
                slidesPerView={2}
                speed={1000}
                spaceBetween={50}
                autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                }}
                pagination={{
                clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper hide-1000 swiper-reviews"
            >
                {review?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="item-container flex-column gap-20 swiper-review-item">
                            <div className="reviews-review">{item?.review}</div>
                            <div className="flex-row center space-between">
                                <div className="flex-row">
                                    <div className="flex-row center gap-10">
                                        <div className="sm-img"><img src={item?.image} alt="" /></div>
                                        <div className="flex-column gap-5 reviews-sm">
                                            <div className="quicksand-font">{item?.firstName} {item?.lastName}</div>
                                            <div className="brown">{item?.occupation}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brown reviews-sm">
                                    {item?.star >0 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >1 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >2 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >3 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >4 && <i class="fa-solid fa-star"></i>}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            
            <Swiper
                slidesPerView={1}
                speed={1000}
                spaceBetween={50}
                autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                }}
                pagination={{
                clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper hide-1001 swiper-reviews"
            >
                {review?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="item-container flex-column gap-20 swiper-review-item">
                            <div className="reviews-review">{item?.review}</div>
                            <div className="flex-row center space-between">
                                <div className="flex-row">
                                    <div className="flex-row center gap-10">
                                        <div className="sm-img"><img src={item?.image} alt="" /></div>
                                        <div className="flex-column gap-5 reviews-sm">
                                            <div className="quicksand-font">{item?.firstName} {item?.lastName}</div>
                                            <div className="brown">{item?.occupation}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="brown reviews-sm">
                                    {item?.star >0 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >1 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >2 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >3 && <i class="fa-solid fa-star"></i>}
                                    {item?.star >4 && <i class="fa-solid fa-star"></i>}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    </>
    
  )
}

export default ReviewItem