import React, {useEffect, useContext, useState} from 'react'
import { Context } from '../components/AppContext';
import ForgotPasswordModal from '../components/forgot-password/ForgotPasswordModal';
import Form from '../components/forgot-password/Form';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendDataWithAxios } from '../config/sendDataWithAxios';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/login/Spinner';
import { motion } from 'framer-motion'
const TermsAndConditions = () => {
    const navigate = useNavigate();
    const {setPage} = useContext(Context);
    useEffect(()=>{
        setPage('terms');
    })
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])

  return (
            <motion.div
            initial={{width : 0}}
            animate={{width : "100%"}}
            exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <div className="arrow-back-t-c" onClick={()=> navigate(-1)}>
            <i class="fa-solid fa-arrow-left"></i>
        </div>
        <div className='forgot-password-container flex-column center t-c'>
            <div className="forgot-password-logo flex-column center gap-20">
                <div className="logo-forgot-password"><img  style={{objectFit:'contain'}} src="/images/milano-logo.png" alt="" /></div>
                <div className="desc t-c-header">Terms and Conditions</div>
            </div>
        </div>
            <div className="t-c-container">
                <div className="welcome">
                    Welcome to Milano Signatures! By Signing up to Milano Signatures or by using Milano Signatures website, you are agreeing to be bound by the following terms and conditions which states as follows;
                </div>
                <div className="t-c-items flex-column center gap-20">
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">It is a condition precedent that the Guest may occupy the room following check-in where he has completed and signed the guest registration form, provided valid identification and provided proof of payment.</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">A 75% deposit of the total accommodation bill plus a refundable caution fee shall be required to make a valid reservation.</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">If you happen to damage any equipment or fixtures inside Milano Signature Apartment’s premises, It would be paid for by the Guest, and amounts shall be paid at the Sole discretion of the Management of Milano Signature Apartment</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">During Occupancy of Milano Signature Apartment, the Guest will not;</div>
                    </div>
                    <div className="p-left">
                        <div className="flex-row gap-20">
                            <div className="">i.</div>
                            <div className="text">Make excessive noise</div>
                        </div>
                        <div className="flex-row gap-20">
                            <div className="">ii.</div>
                            <div className="text">Damage or remove Milano Signature property, whether in the room or any part of the premises</div>
                        </div>
                        <div className="flex-row gap-20">
                            <div className="">iii.</div>
                            <div className="text">Leave the room in a disorderly state or in a state that would cause distress to the cleaners.</div>
                        </div>
                        <div className="flex-row gap-20">
                            <div className="">iv.</div>
                            <div className="text">Park a car or other vehicle otherwise than as agreed by Milano Signature Apartment or cause Obstruction around the Apartment.</div>
                        </div>
                        <div className="flex-row gap-20">
                            <div className="">v.</div>
                            <div className="text">Enter or Occupy any room which has not been allocated to Guest or Obstruct any parts of Milano Signatures Apartment.</div>
                        </div>
                        <div className="flex-row gap-20">
                            <div className="">vi.</div>
                            <div className="text">Interfere with other Guests, their property or their enjoyment and peaceful stay at Milano Signature Apartment.</div>
                        </div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">We don't or permit anything which is illegal or which may become a nuisance including the infringement of any third party intellectual property rights particular whilst using Milano Signature's Apartment internet connection.</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">Smoking is not allowed  in all of our properties, smoking can be done outside or at the rooftop of the premises</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">No drugs or weeds are allowed in all of our properties. Any Guest found flouting this rule shall be ejected from our properties without recourse for refunds.</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">Cigarette burnt attracts a fine</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">Pets are not allowed in the apartment</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">Get-Together's/parties warrants a caution fee for deep cleaning if required and stipulated by Milano Signature Apartment. Where damages occur, guests may be charged depending on the cost of damages as stipulated by Milano Signature Apartment. All these charges are avoidable if Guests obey the Milano Signature Apartment rules.</div>
                    </div>
                    <div className="flex-row gap-20">
                        <div className=""><i class="fa-solid fa-plus"></i></div>
                        <div className="text">The Information provided to the customer service by the Guest during booking will be handled in accordance with applicable data protection requirements</div>
                    </div>
                </div>
                <div className="note">
                    <b>PLEASE NOTE</b>: all parties/Get-Togethers should end by 10:00pm.
                    For Get/Togethers/parting/hosting, a maximum number of 3 adults are allowed to occupy each bedroom of the Apartment.
                </div>
                <div className="button t-c">
                <a href="/files/Terms.pdf" >
                Download our Terms of Service Pdf
                </a>
                </div>
            </div>
        <ToastContainer />
    </motion.div>
  )
}

export default TermsAndConditions