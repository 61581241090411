import React from 'react';
import { useNavigate } from 'react-router-dom';

const MobileSearchButton = ({ selectedApartment }) => {
  const navigate = useNavigate();

  const handleSearch = () => {
    // If no apartment is selected, do not navigate
    if (!selectedApartment) {
      return;
    }

    // Redirect to the appropriate page based on the selected apartment
    navigate(`/apartment/${encodeURIComponent(selectedApartment.id)}`);
  };

  return (
    <div className='flex-column gap-10  center'>
    <div className='hamburger-button sm button brown' id='six' onClick={handleSearch}>
      <div className='item-flex'>Search</div>
    </div>
    </div>
  );
};

export default MobileSearchButton;
