import React, { useState , useEffect} from 'react'
import Footer from '../components/home/Footer'
import { sendDataWithAxios } from '../config/sendDataWithAxios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom'
import ReviewsForm from '../components/reviews/ReviewsForm';
import Rating from '../components/reviews/Rating';
import { motion } from 'framer-motion'


const Review = () => {
    const navigate = useNavigate();
    const [star, setStar] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [comment, setComment] = useState('');
    const [email, setEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const validateInputs = () =>{
        if(!email || !firstName || !lastName || !mobile || !comment || !star || !occupation){
            toast.error('Enter required fields');
            return false
        }else{
            return true
        }
    }
    const isValidEmail = () =>{
        const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(!emailRegex.test(email)){
            toast.error('Invalid Email Address');
            return false
        }else{
            return true
        }
    }
    const submitForm = async(e) => {
        e.preventDefault();
        if(validateInputs() && isValidEmail()){
            let result;
            try {
                result = await sendDataWithAxios('/reviews/addReview', {
                email,
                star,
                firstName,
                lastName,
                review: comment,
                mobile,
                occupation
            });
                console.log(result);
                if(result?.data?.requestSuccessful){
                    toast.success('Success!')
                    setTimeout(()=>{
                        navigate(-1);
                    }, 500)
                }else{
                    toast.error('invalid');
                }
            } catch (error) {
                console.log(error);
                toast.error(error?.response.data?.message);
            }
            
        }
    }
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <div className="overlay-container-single">
              <div className="top">
                  <img src="/images/single-apartment-container-bg.jpg" alt="" />
              </div>
              <div className="logo-single-apartment">
                  <img src="/images/single-apartment-logo.png" alt="" />
              </div>
          </div>
        <div className="review-form-container">
            <div className="section-title-review">Leave a Review</div>
            <ReviewsForm firstName={firstName} lastName={lastName} setFirstName={setFirstName} setLastName={setLastName} mobile={mobile} setMobile={setMobile} email={email} setEmail={setEmail} occupation={occupation} setOccupation={setOccupation} comment={comment} setComment={setComment}/>
            <div className="rating-star-container gap-20">
                Leave a Rating 
                <Rating star={star} setStar={setStar}/>
            </div>
            <div className="buttons-review">
                <div className="back-buttons-review flex-row center">Back</div>
                <div className="button brown flex-row center" onClick={(e)=>submitForm(e)}>Submit</div>
            </div>
        </div>
        <ToastContainer />
        <Footer />
    </motion.div>

  )
}

export default Review