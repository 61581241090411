import React from 'react'
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';


const HeroSlider = ({ heroBigText, animationMode, setAnimationMode, offAnimation, slideIndex, setSlideIndex, smallText, orangeText }) => {
    const navigate = useNavigate();
    return (
        <div className='hero-container'>
            <div className="relative h-screen w-screen">
                <video autoPlay muted loop playsInline className="w-full hidden md:flex h-full object-cover absolute top-0 left-0">
                    <source src="/videos/test.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <img src='/images/about-image-1.jpg' className='w-full  md:hidden h-full object-cover absolute top-0 left-0' />
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                    <h1 className="text-center uppercase flex md:hidden flex-col items-center text-3xl md:text-7xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#CBA135] to-white">
                        Milano Signatures
                        <span className="text-lg md:text-xl font-medium text-[#CBA135]">
                            <TypeAnimation
                                sequence={[
                                    // Same substring at the start will only be typed out once, initially
                                    'Milano Signatures-A new dimension for luxury and Relaxation.',
                                    1000, // wait 1s before replacing "Mice" with "Hamsters"
                                    'Book Your Ride Now! Our Top-notch Drivers Will Whisk You Away to Any Destination You Desire',
                                    1000,
                                    'Milano Signatures-A Place to relax and have a good time with your family',
                                    1000,
                                    'At Milano Signatures, We have the best chefs to give you any meal of your choice.',
                                    1000
                                ]}
                                wrapper="span"
                                speed={50}
                                style={{ fontSize: '1em', display: 'inline-block' }}
                                repeat={Infinity}
                            />
                        </span>
                    </h1>
                    <div className="flex flex-col items-center justify-center mt-10">
                        <button
                            className="bg-[#CBA135] text-white px-4 py-2 rounded-md md:hidden"
                            onClick={() => navigate('/apartments')}
                        >
                            Book Now
                        </button>
                        {/* <button
                            className="hidden md:flex border border-[#CBA135] px-4 py-2 rounded-md bg-transparent text-3xl text-white hover:bg-[#CBA135]
                            hover:text-white"
                            onClick={() => navigate('/apartments')}
                        >
                            Book Now
                        </button> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSlider