import axios from "axios";

const API_URL = "https://milano-q5fy.onrender.com";

// http://localhost:5000/api/apartments

//Get all review
const getReview = async (token) => {
    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     },
    // };

    const response = await axios.get(API_URL + "/api/review");

    if(response.data) {
        localStorage.setItem("review", JSON.stringify(response.data));
    }

    return response.data;
}


const reviewService = {
    getReview,
};


export default reviewService;