import React from 'react'

const GridOne = () => {
  return (
        <div className="grid one">
          <div className="about-desc flex-column gap-20">
            <div className="title flex-row center">
                Brief Summary
            </div>
            <div className="summary flex-column gap-20">
                <span className='about-texts'>Milano Signature Apartments, conveniently situated in the heart of Magodo, Lagos is one of the most flexible and reliable apartments. It offers online room booking and reservations for family rooms, studios, two bedroom apartment, three bedroom apartment for a day, a week or as required.</span>
                <span className='about-texts'>Milano Signature Apartment gives you more value for your money as we provide you with a home away from home Experience. </span>
                <span className='about-texts'>Our Apartments are fully serviced, Luxuriously furnished and Budget friendly apartments for our visitors. We take pride in our service delivery and are delighted to present you with our furnished apartments that will make your lodging experience memorable.</span>
            </div>
          </div>
          <div className="images">
            <div className="">
                <img src="images/hero-image-1.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-2.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-3.jpg" alt="" />
            </div>
            <div className="">
                <img src="images/hero-image-1.jpg" alt="" />
            </div>
          </div>
        </div>
  )
}

export default GridOne