import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { useAuth } from '../../contexts/AuthContext';
import { to } from 'react-spring';
import { toast } from 'react-toastify';


const NavLinks = ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();

  // const onLogOut = async () => {
  //   try {
  //     await logout();
  //     navigate('/login');
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }



  return (
    <div className='flex flex-col text-xl'>
      <ul class="hidden text-[#CBA135] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
        <li><a class="text-sm text-[#CBA135]" href="/">Home</a></li>
        <li class="text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </li>
        <li><a class="text-sm text-[#CBA135] font-bold" href="/about">About Us</a></li>
        <li class="text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </li>
        <li><a class="text-sm text-[#CBA135] " href="/apartments">Apartments</a></li>
        <li class="text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </li>
        <li><a class="text-sm text-[#CBA135]" href="/gallery">Gallery</a></li>
        <li class="text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" class="w-4 h-4 current-fill" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </li>
        <li><a class="text-sm text-[#CBA135]" href="/contact">Contact</a></li>
      </ul>

      {/* <div>
        {currentUser ? (
          // Content to render when user is logged in
          <button onClick={onLogOut} className="text-sm text-[#CBA135] font-bold">Logout</button>
        ) : (
          // Content to render when user is not logged in
          <>
            <ul class="flex space-x-5">
              <li class="text-white">
                <a class="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-[#CBA135] rounded-xl" href="/login">Sign In</a>
              </li>
              <li class="text-white">
                <a class="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-[#CBA135] rounded-xl" href="/signup">Sign up</a>
              </li>
            </ul>
          </>
        )}
      </div> */}

    </div>
  )
}

export default NavLinks