import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Cardflip from '../cardflip/Cardflip';
import first from '../cardflip/images/soup.png';
import second from '../cardflip/images/catering1.jpg';
import third from '../cardflip/images/test2.avif';
import fourth from '../cardflip/images/test3.avif';
import fifth from '../cardflip/images/chaffeur.jpg';
import sixth from '../cardflip/images/chaffeur1.jpg';
import seventh from '../cardflip/images/mini.png';
import eighth from '../cardflip/images/mini1.jpg';

const Services = () => {

  const images = [first, third, fifth, seventh];
  const title = ['Catering', 'Laundry', 'Chauffeur', 'Mini Lounge'];
  const descriptions = [
    "Enjoy Mouth-watering Dishes at Milano Signatures Apartment.",
    'Enjoy Premium Laundry where we wash, dry and fold your clothes at affordable prices',
    'Book a Chauffeur today. We have the best drivers to take you to anywhere of your choice',
    'Have fun and drinks at the Milano Lounge.  We have exclusive lounge for our guests to have a good time.',
  ];
  const buttonTitle = ['Order Now', 'Call Us', 'Call Now', 'Contact Us'];
  const cardLinks = ['/food', '/laundry', '/ride', '/bar'];

  return (
    <div className=''>
      {/* <div className="section-text">Our Services</div> */}
      {/* <Cardflip
        cards={[
          {
            imageFront: first,
            titleFront: 'Catering',
            descriptionFront: 'Enjoy Mouth-watering Dishes at Milano Signatures Apartment.',
            imageBack: second,
            titleBack: 'Catering',
            descriptionBack: 'We have the best chefs to give you any meal of your choice.',
            button: 'Order Now',
            route: '/food'
          },
          {
            imageFront: third,
            titleFront: 'Laundry',
            descriptionFront: 'Enjoy Premium Laundry where we wash, dry and fold your clothes at affordable prices',
            imageBack: fourth,
            titleBack: 'Laundry',
            descriptionBack: 'Enjoy Premium Laundry where we wash, dry and fold your clothes at affordable prices',
            button: 'Order Now',
            route: '/laundry'
          },
          {
            imageFront: fifth,
            titleFront: 'Chauffeur',
            descriptionFront: 'Book a Chauffeur today. We have the best drivers to take you to anywhere of your choice',
            imageBack: sixth,
            titleBack: 'Chauffeur',
            descriptionBack: 'Your safety is our priority. We have the best drivers to take you to anywhere of your choice',
            button: 'Call Now',
            route: '/ride'
          },
          {
            imageFront: seventh,
            titleFront: 'Mini Lounge',
            descriptionFront: 'Have fun and drinks at the Milano Lounge.  We have exclusive lounge for our guests to have a good time.',
            imageBack: eighth,
            titleBack: 'Laundry',
            descriptionBack: 'Feel at home at the Milano Lounge. Your comfort is our priority.',
            button: 'Contact Us',
            route: '/bar'
          },
          // Add more card objects here
        ]}
      /> */}

      <Cardflip
        cardImages={images}
        cardDescriptions={descriptions}
        cardTitle={title}
        cardLinks={cardLinks}
        buttonTitle={buttonTitle}
      />

    </div>
  )
}

export default Services
