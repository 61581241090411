import React from 'react'
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperContainer from '../apartment/SwiperContainer';
import ApartmentInfo from '../apartment/ApartmentInfo';
import { useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import OrderSummary from '../apartment/OrderSummary';
import KYC from '../Steps/KYC';
import { useAuth } from '../../contexts/AuthContext';

function PopUp({ item }) {
    // const { user } = useSelector((state) => state.auth);
    const { currentUser } = useAuth();
    const [step, setStep] = useState(1);
    const [imageURL, setImageURL] = useState('');


    const [kycData, setKycData] = useState({});
    const [orderSummaryData, setOrderSummaryData] = useState({});

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        checkIn: '',
        checkOut: '',
        guests: 'individuals',
        getDays: '',
        photo: '',
        countryCode: '+234',
        amount: item.price,
        apartmentName: item.name,
    });

    const handleKYCFileChange = (file) => {
        setKycData({
            ...kycData,
            iD: file,
        });
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    const navigate = useNavigate();
    // const [mode, setMode] = useState(1);
    const summaryPage = useRef(null);
    const infoPage = useRef(null);
    const kycPage = useRef(null);
    const paymentPage = useRef(null);
    const firstNameRef = useRef();
    const { id } = useParams();
    const [iD, setId] = useState('');
    const [apartmentInfo, setApartmentInfo] = useState({
        images: []
    });
    const [countryCode, setCountryCode] = useState('');
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);


    const handleCountryCodeChange = (selectedOption) => {
        setSelectedCountryCode(selectedOption);
    };

    const [apartmentPrice, setApartmentPrice] = useState({});

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])


    const validateInputs = () => {
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.address || !formData.checkIn || !formData.checkOut || !formData.guests) {
            return false
        } else {
            return true
        }
    }
    const isValidEmail = () => {
        const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(formData.email)) {
            return false
        } else {
            return true
        }
    }
    const verifyDates = () => {
        // let checkIn = new Date(checkIn);
        // let checkOut = new Date(checkOut);
        if (new Date(formData.checkIn).getTime() > new Date(formData.checkOut).getTime()) {
            return false
        }
        return true
    }

    const isLastStep = step === 3;

    const isValid = validateInputs() && isValidEmail() && verifyDates();

    const getDays = (checkIn, checkOut) => {
        const checkInTime = new Date(checkIn).getTime();
        const checkOutTime = new Date(checkOut).getTime();
        const timeDiff = checkOutTime - checkInTime;
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDiff;
    };




    const [code, setCode] = useState('+234');
    const country_codes = [
        "+234", "+93", "+355", "+213", "+1-684", "+376", "+244", "+1-264", "+672", "+1-268",
        "+54", "+374", "+297", "+61", "+43", "+994", "+1-242", "+973", "+880", "+1-246",
        "+375", "+32", "+501", "+229", "+1-441", "+975", "+591", "+387", "+267", "+55",
        "+246", "+673", "+359", "+226", "+257", "+855", "+237", "+1", "+238", "+1-345",
        "+236", "+235", "+56", "+86", "+61", "+61", "+57", "+269", "+682", "+506",
        "+385", "+53", "+599", "+357", "+420", "+243", "+45", "+253", "+1-767",
        "+1-809", "+1-829", "+1-849", "+670", "+593", "+20", "+503", "+240", "+291",
        "+372", "+268", "+251", "+500", "+298", "+679", "+358", "+33", "+689", "+241",
        "+220", "+995", "+49", "+233", "+350", "+30", "+299", "+1-473", "+1-671",
        "+502", "+44-1481", "+224", "+245", "+592", "+509", "+504", "+852", "+36",
        "+354", "+91", "+62", "+98", "+964", "+353", "+44-1624", "+972", "+39",
        "+225", "+1-876", "+81", "+44-1534", "+962", "+7", "+254", "+686", "+383",
        "+965", "+996", "+856", "+371", "+961", "+266", "+231", "+218", "+423",
        "+370", "+352", "+853", "+261", "+265", "+60", "+960", "+223", "+356",
        "+692", "+222", "+230", "+262", "+52", "+691", "+373", "+377", "+976",
        "+382", "+1-664", "+212", "+258", "+95", "+264", "+674", "+977", "+31",
        "+687", "+64", "+505", "+227", "+234", "+683", "+672", "+850", "+389",
        "+1-670", "+47", "+968", "+92", "+680", "+970", "+507", "+675", "+595",
        "+51", "+63", "+64", "+48", "+351", "+1-787", "+1-939", "+974", "+242",
        "+262", "+40", "+7", "+250", "+590", "+290", "+1-869", "+1-758"
    ]







    // Update form data when inputs change
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update other form data properties as usual
        setFormData({
            ...formData,
            [name]: value,
        });

        // Calculate days if the change occurs in check-in or check-out
        if (name === 'checkIn' || name === 'checkOut') {
            const days = getDays(name === 'checkIn' ? value : formData.checkIn, name === 'checkOut' ? value : formData.checkOut);
            setFormData(prevFormData => ({
                ...prevFormData,
                getDays: days,
            }));
        }
    };




    // Function to handle the "Next" button click
    const handleNextClick = () => {
        // Save form data to state
        setFormData({
            ...formData,
            amount: formData.getDays * item.price,
            photo: imageURL,
            phone: formData.countryCode + formData.phone,
            // Add other form data properties if needed
        });
        nextStep();
        saveFormDataToLocalStorage();
    };

    const saveFormDataToLocalStorage = () => {
        localStorage.setItem('formData', JSON.stringify(formData));
    };



    switch (step) {
        case 1:
            return (
                <>
                    {apartmentInfo && (
                        <>
                            <SwiperContainer apartmentInfo={item} />
                            <ApartmentInfo apartmentInfo={item} />
                        </>
                    )}
                    <div className='p-4'>
                        <div className='flex items-center space-x-4 mb-2'>
                            <div className='bg-[#CBA135] text-white rounded-full w-6 flex justify-center items-center'>
                                <p className=''>1</p>
                            </div>
                            <h1>Please Fill Your Details </h1>
                        </div>
                        <form class="w-full max-w-lg">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        First Name
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.firstName}
                                        name='firstName'
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" required />
                                </div>
                                <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Last Name
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.lastName}
                                        name='lastName'
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe" required />
                                </div>
                            </div>
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        E mail
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.email}
                                        name='email'
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="email" placeholder="example@mail.com" required />
                                </div>
                                <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Address
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        value={formData.address}
                                        name='address'
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="11, John Street, Lagos" />
                                </div>
                            </div>
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                                        Guests
                                    </label>
                                    <div class="relative">
                                        <select
                                            onChange={handleChange}
                                            value={formData.guests}
                                            name='guests'
                                            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                            <option>Individuals</option>
                                            <option>Filmmakers</option>
                                            <option>Party</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Phone Number
                                    </label>
                                    <div className='flex items-center gap-4'>
                                        <select
                                            onChange={handleChange}
                                            value={formData.countryCode}
                                            name='countryCode'
                                            class="block appearance-none w-1/4 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                                        >
                                            {
                                                country_codes.map((c, index) => (
                                                    <option value={c} key={index}>{c}</option>
                                                ))
                                            }
                                        </select>
                                        <input
                                            onChange={handleChange}
                                            value={formData.phone}
                                            name='phone'
                                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" placeholder="08090000000" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className='w-full md:w-1/2 px-3'>
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Check in
                                    </label>
                                    <input type="date"
                                        onChange={handleChange}
                                        value={formData.checkIn}
                                        name='checkIn'
                                        className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' />
                                </div>
                                <div className='w-full md:w-1/2 px-3'>
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Check out
                                    </label>
                                    <input type="date"
                                        onChange={handleChange}
                                        value={formData.checkOut}
                                        name='checkOut'

                                        className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                                    />
                                </div>
                            </div>
                        </form>
                        <button
                            onClick={handleNextClick}
                            disabled={!isValid}
                            className={`p-2 rounded-md ${isValid ? 'bg-[#CBA135] text-white px-4 py-2 rounded-md' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                        >
                            Next
                        </button>

                    </div>
                </>
            );
        case 2:
            return (
                <>
                    {apartmentInfo && (
                        <>
                            <SwiperContainer apartmentInfo={item} />
                            <ApartmentInfo apartmentInfo={item} />
                        </>
                    )}
                    <KYC
                        onFileChange={handleKYCFileChange}
                        iD={iD}
                        setId={setId}
                        imageURL={imageURL}
                        setImageURL={setImageURL}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        item={item}
                    />
                </>
            );
        case 3:
            return (
                <>
                    {apartmentInfo && (
                        <>
                            <SwiperContainer apartmentInfo={item} />
                            <ApartmentInfo apartmentInfo={item} />
                        </>
                    )}
                    <OrderSummary
                        nextStep={nextStep}
                        apartmentPrice={apartmentPrice}
                        item={item}
                        formData={formData}
                        verifyDates={verifyDates}
                        isValidEmail={isValidEmail}
                        validateInputs={validateInputs}
                    />
                    {isLastStep && (
                        <div className="flex items-center justify-center mt-4">
                            <button onClick={prevStep} className="p-2 mr-2 bg-gray-300 text-white rounded-md">
                                Previous
                            </button>
                            {/* <button onClick={submitForm} className={`bg-[#CBA135] text-white px-4 py-2 rounded-md`}>
                                Pay Now
                            </button> */}
                            <Link to={`/summary/apartment/${item._id}`} className="p-2 ml-2 bg-gray-300 text-white rounded-md">
                                Summary
                            </Link>
                        </div>
                    )}
                </>
            );
        default:
            return null;
    }


}

export default PopUp