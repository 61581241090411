import React, { useEffect, useState } from 'react'
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiper } from 'swiper/react';
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useNavigate } from 'react-router-dom'
import HeroSlider from './HeroSlider';
import MobileHeroSearch from './MobileHeroSearch';
import DesktopSearch from './DesktopSearch';
import Nav from './Nav';

const Hero = ({ page, setPage }) => {
    const [hamburger, setHamburger] = useState('close');
    const [showArrivalDate, setShowArrivalDate] = useState(false);
    const [showDepartureDate, setShowDepartureDate] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setPage('home');
    })
    let [slideIndex, setSlideIndex] = useState(0);
    const [arrivalInputType, setArrivalInputType] = useState('text');
    const [arrivalInput, setArrivalInput] = useState('');
    const [departureInputType, setDepartureInputType] = useState('text');
    const [departureInput, setDepartureInput] = useState('');
    const [locationInput, setLocationInput] = useState('');
    const [animationMode, setAnimationMode] = useState(false);
    const heroBigText = [
        "The Privacy and  ",
        "Experience comfort with ",
        "A new dimension of ",
        "Home away from ",
        "just wish it",
    ];
    const orangeText = [
        "Individuality of a custom",
        "our Exclusive ride",
        "luxury and relaxation",
        "home",
        "we dish it",
    ]
    const smallText = [
        // "Milano Signatures-A new dimension for luxury and Relaxation.",
        // "Book Your Ride Now! Our Top-notch Drivers Will Whisk You Away to Any Destination You Desire",
        // "Milano Signatures-A new dimension for luxury and Relaxation.",
        // "Milano Signatures-A Place to relax and have a good time with your family.",
        // "At Milano Signatures, We have the best chefs to give you any meal of your choice.",
    ]
    const offAnimation = () => {
        setTimeout(() => {
            setAnimationMode(false);
        }, 1000)
    }
    return (<>
        {/* <HeroSlider heroBigText={heroBigText} animationMode={animationMode} setAnimationMode={setAnimationMode} offAnimation={offAnimation} slideIndex={slideIndex} setSlideIndex={setSlideIndex} smallText={smallText} orangeText={orangeText}/> */}
        <div className="">
            <HeroSlider />
            <Nav background="bg-red-500" />
            <DesktopSearch arrivalInputType={arrivalInputType} setArrivalInputType={setArrivalInputType} arrivalInput={arrivalInput} setArrivalInput={setArrivalInput} departureInput={departureInput} setDepartureInput={setDepartureInput} departureInputType={departureInputType} setDepartureInputType={setDepartureInputType} />
            <MobileHeroSearch setHamburger={setHamburger} hamburger={hamburger} />
        </div>
    </>

    )
}

export default Hero