import React, { useState, useRef } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendDataWithAxios } from '../../config/sendDataWithAxios';
import { useNavigate } from 'react-router-dom';
import Spinner from '../login/Spinner';
import { useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import Logo from "./milano-logo.png";
import axios from 'axios';



const BankDetails = ({ onClose }) => {
    return (
        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8  dark:bg-gray-800">
            <div className="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                    <div className="flex justify-end">
                        <button className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 z-10" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">Bank Details</h3>
                    <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                        <div className="flex justify-between w-full">
                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Bank Name</p>
                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">PROVIDUS BANK</p>
                        </div>
                        <div className="flex justify-between items-center w-full">
                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Account Number</p>
                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">5400825255</p>
                        </div>
                        <div className="flex justify-between items-center w-full">
                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Account Name</p>
                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">WESOLVEITBETA INVESTMENTS LTD</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const OrderSummary = () => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    const [formData, setFormData] = useState({});

    const [formattedPrice, setFormattedPrice] = useState(null);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);


    const selectedItem = JSON.parse(localStorage.getItem('formData'));

    useEffect(() => {
        if (selectedItem && selectedItem.price) {
            const price = formatPrice(selectedItem.price);
            setFormattedPrice(price);
        }
    }, []);


    const formatPrice = (price) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(price);
    };

    const cautionFee = formatPrice(50000);
    const apartmentPrice = formatPrice(selectedItem.amount);
    const TotalPrice = formatPrice((selectedItem.amount * selectedItem.number_of_nights) + 50000);






    const summaryPage = useRef(null);

    const [showBankDetails, setShowBankDetails] = useState(false);

    const handleBankTransferClick = () => {
        setShowBankDetails(true);
    };

    const handleCloseBankDetails = () => {
        setShowBankDetails(false);
    };

    const handlePrint = () => {
        const printContent = document.getElementById('summary-content');

        if (printContent) {
            const originalContent = document.body.innerHTML;
            const content = printContent.innerHTML;

            document.body.innerHTML = content;
            window.print();

            document.body.innerHTML = originalContent;
        } else {
            console.error('Content not found for printing.');
        }
    };



    const handlePayment = async (e) => {
        e.preventDefault();
        setIsLoadingPayment(true);

        const calculatedTotalPrice = selectedItem.amount * selectedItem.number_of_nights + 50000;

        const generatePaymentLink = async () => {
            const paymentData = {
                amount: calculatedTotalPrice * 100,
                email: selectedItem.email,
            };

            try {
                const response = await axios.post('https://api.paystack.co/transaction/initialize', paymentData, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
                    },
                });

                return response.data.data;
            } catch (error) {
                // Handle error
                console.error(error);
                return null;
            }
        };



        // axios.post('https://milano-q5fy.onrender.com/api/form/submitForm', formData)
        //     .then((response) => {
        //         response.data.status === 'success' ? toast.success('Form submitted successfully') : toast.error(response.data.message)
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })
        //     .finally(() => {
        //         setIsLoadingPayment(false);
        //     });



        generatePaymentLink().then((paymentLink) => {
            if (paymentLink) {
                // Redirect the user to Paystack payment page
                window.location.href = paymentLink.authorization_url;
            } else {
                // Handle error in generating payment link
                toast.error('Failed to initiate payment');
                setIsLoadingPayment(false);
            }

        }).finally(() => {
            localStorage.setItem('paymentSuccess', 'true');
        });
    };

    return (
        <div id="summary-content" className='' style={{
            position: 'relative',
        }}>
            <img
                src="/images/milano-logo.png"
                alt="Watermark"
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    opacity: '0.1',
                }}
            />
            <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto"

            >
                <div className="flex justify-start item-start flex-col">
                    <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800"></h1>
                </div>
                <div className="mt-5 flex flex-col xl:flex-row justify-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
                    <div className="mt-10 flex flex-col xl:flex-row justify-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
                        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
                            <div className="flex justify-center flex-col md:flex-row items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                                <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                                    <h3 className="text-2xl dark:text-white font-semibold leading-5 text-gray-800">Summary</h3>
                                    <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                                        <div className="flex justify-between w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Price</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">{apartmentPrice}</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Internet Service</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">NGN0.00</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Room Service</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">NGN0.00</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Washer</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">NGN0.00</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Premium Netflix</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">NGN0.00</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Alexa</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">NGN0.00</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Total Days</p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">{selectedItem.number_of_nights}</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base font-bold dark:text-white leading-4 text-gray-800">Caution Fee <span className="bg-gray-200 p-1 text-xs font-medium dark:bg-white dark:text-gray-800 leading-3 text-gray-800">Refundable</span></p>
                                            <p className="text-base font-bold dark:text-gray-300 leading-4 text-gray-600">{cautionFee}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center w-full">
                                        <p className="text-base font-bold dark:text-white  leading-4 text-gray-800">Total</p>
                                        <p className="text-base font-bold dark:text-gray-300  leading-4 text-gray-600">{TotalPrice}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-800 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
                        <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                            <div className="flex flex-col justify-start items-start flex-shrink-0">
                                <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                                    <div className="flex justify-start items-start flex-col space-y-2">
                                        <p className="text-base font-bold dark:text-white  leading-4 text-left text-gray-800">{selectedItem.firstName} {selectedItem.lastName}</p>
                                    </div>
                                </div>

                                <div className="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3 7L12 13L21 7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <p className="cursor-pointer text-sm leading-5 ">{selectedItem.email}</p>
                                </div>
                            </div>
                            <div className="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                                <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                                    <div className="flex w-full mt-4 justify-center items-center md:justify-start md:items-start">
                                        <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4">
                                            <p className="text-base font-bold dark:text-white  leading-4 text-center md:text-left text-gray-800">Check In</p>
                                            <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">{selectedItem.check_in_date}</p>
                                        </div>
                                        <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4">
                                            <p className="text-base font-bold dark:text-white  leading-4 text-center md:text-left text-gray-800">Check Out</p>
                                            <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">{selectedItem.check_out_date}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex z-10 w-full gap-4 justify-center items-center md:justify-start md:items-start">
                                    <button
                                        onClick={handleBankTransferClick}
                                        className="cursor-pointer mt-6 md:mt-0 dark:border-white dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base font-bold font-medium leading-4 text-gray-800">
                                        Bank Transfer
                                    </button>
                                    <button
                                        onClick={handlePayment}
                                        disabled={isLoadingPayment}
                                        className="z-10 cursor-pointer mt-6 md:mt-0 dark:border-white dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base font-bold font-medium leading-4 text-gray-800">
                                        {isLoadingPayment ? <Spinner /> : 'Pay Online'}
                                    </button>
                                </div>
                                {showBankDetails && <BankDetails onClose={handleCloseBankDetails} />}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 items-center justify-between xl:h-full  w-full mt-6 md:mt-2">
                    <div
                        onClick={() => {
                            window.open('https://wa.me/message/D65RI5UBOW4TH1', '_blank')
                        }}
                        className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8 cursor-pointer">
                        <p className="text-base font-bold dark:text-white  leading-4 text-center md:text-left text-gray-800 flex items-center gap-5 ">
                            <i className="fa-brands fa-whatsapp"></i>
                            WhatsApp
                        </p>
                    </div>
                    <div
                        className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8 cursor-pointer">
                        <p className="text-base font-bold dark:text-white  leading-4 text-center md:text-left text-gray-800 flex items-center gap-5 ">
                            <i className="fa-solid fa-phone"></i>
                            +2349087429197
                        </p>
                    </div>
                    <div
                        onClick={() => {
                            window.open('mailto:milanosignatureint@gmail.com', '_blank')
                        }}
                        className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8 cursor-pointer">
                        <p className="text-base font-bold dark:text-white leading-4 text-center md:text-left text-gray-800 flex items-center gap-5 ">
                            <i className="fa-regular fa-envelope"></i>
                            <span>milanosignatureint@gmail.com</span>
                        </p>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handlePrint}
                        className="mt- md:mt-0 dark:border-white dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base font-bold font-medium leading-4 text-gray-800">
                        Print
                    </button>
                </div>
            </div >
        </div>
    )
}

export default OrderSummary