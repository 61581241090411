import React from 'react'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Apartments from "./Apartments";
import Login from "./Login";
import SignUp from "./SignUp";
import Apartment from "./Apartment";
import AppContext from "../components/AppContext";
import Food from "./Food";
import Ride from "./Ride";
import Laundry from "./Laundry";
import Review from "./Review";
import ContactUs from "./ContactUs";
import Bar from "./Bar";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Gallery from "./Gallery";
import TermsAndConditions from "./TermsAndConditions";
import About from "./About";
import PaymentSuccess from "./PaymentSuccess";
import { AnimatePresence } from 'framer-motion';
import Payment from './ContactS';
import ContactS from './ContactS';
import Summary from './Summary';
import OrderSummary from '../components/apartment/OrderSummary';
import Kyc from './Kyc';
import PopUp from '../components/apartments/PopUp';
import ApartmentInfo from '../components/apartment/ApartmentInfo';
import PaymentSuccessRoute from '../features/auth/PaymentSuccessRoute';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path='/'
          element={
            <Home
            />
          }
        />
        <Route
          path='/apartments'
          element={
            <Apartments
            />
          }
        />
        <Route
          path='/apartments/:id'
          element={
            <ApartmentInfo
            />
          }
        />
        <Route
          path='/summary/apartment/:id'
          element={
            <Summary
            />
          }
        />
        <Route
          path='/food'
          element={
            <Food
            />
          }
        />
        <Route
          path='/ride'
          element={
            <Ride
            />
          }
        />
        <Route
          path='/laundry'
          element={
            <Laundry
            />
          }
        />
        <Route
          path='/review'
          element={
            <Review
            />
          }
        />
        <Route
          path='/contact'
          element={
            <ContactUs
            />
          }
        />
        <Route
          path='/bar'
          element={
            <Bar
            />
          }
        />
        <Route
          path='/gallery'
          element={
            <Gallery
            />
          }
        />
        <Route
          path='/terms'
          element={
            <TermsAndConditions
            />
          }
        />
        <Route
          path='/about'
          element={
            <About
            />
          }
        />
        <Route
          path='/payment'
          element={
            <Payment
            />
          }
        />
        <Route
          path='/contact-us-success'
          element={
            <ContactS
            />
          }
        />
        <Route
          path='/success'
          element={
            <PaymentSuccessRoute>
              <PaymentSuccess
              />
            </PaymentSuccessRoute>
          }
        />
        <Route
          path='/kyc'
          element={
            <Kyc
            />
          }
        />
        <Route
          path='*'
          element={
            <Home
            />
          }
        />
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes