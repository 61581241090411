import React, { useState, useEffect } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import emailjs from 'emailjs-com';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RoomRentalAgreementForm = () => {

    const [imageUpload, setImageUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const uploadImage = async (file) => {
        if (file === null) return;
        const imageName = v4();
        const imageRef = ref(storage, `images/users/${imageName + file.name}`);
        try {
            await uploadBytes(imageRef, file);
            console.log('Uploaded');
            setUploadSuccess(true);
            toast.success('Upload successful');
            const downloadURL = await getDownloadURL(imageRef);
            setImageURL(downloadURL);
            setFormData(prevState => ({ ...prevState, idFile: downloadURL }));
        } catch (error) {
            console.error('Upload failed:', error);
            toast.error('Upload failed');
        }
    };


    const [formData, setFormData] = useState({
        customerName: '',
        birthday: '',
        email: '',
        officeAddress: '',
        houseAddress: '',
        phoneNumber: '',
        nextOfKin: '',
        nextOfKinPhoneNumber: '',
        idFile: '',
        idNumber: '',
        checkInDate: '',
        checkOutDate: '',
        apartmentChoice: [],
        numberOfNights: '',
        occupation: '',
        instagramHandle: '',
        comments: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                apartmentChoice: checked
                    ? [...prevState.apartmentChoice, value]
                    : prevState.apartmentChoice.filter(choice => choice !== value)
            }));
        } else if (type === 'file') {
            setFormData(prevState => ({ ...prevState, [name]: files[0] }));
            uploadImage(files[0]);
        } else if (type === 'tel' || name === 'numberOfNights') {
            const numericValue = value.replace(/\D/g, '');
            setFormData(prevState => ({ ...prevState, [name]: numericValue }));
        } else {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const selectedDate = new Date(formData.checkInDate);
        const today = new Date();

        if (selectedDate < today) {
            return toast.error('Check in date cannot be in the past');
        }

        const templateParams = {
            customer_name: formData.customerName,
            birthday: formData.birthday,
            email: formData.email,
            office_address: formData.officeAddress,
            house_address: formData.houseAddress,
            phone_number: formData.phoneNumber,
            next_of_kin: formData.nextOfKin,
            next_of_kin_phone_number: formData.nextOfKinPhoneNumber,
            id_file: formData.idFile,
            id_number: formData.idNumber,
            check_in_date: formData.checkInDate,
            check_out_date: formData.checkOutDate,
            apartment_choice: formData.apartmentChoice.join(', '),
            number_of_nights: formData.numberOfNights,
            occupation: formData.occupation,
            instagram_handle: formData.instagramHandle,
            comments: formData.comments
        };

        emailjs.send('service_6zb2qrp', 'template_j6jr7bm', templateParams, '_AWyO7mQxGs5uQBO6')
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
            }, error => {
                console.log('FAILED...', error);
            });
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-center">Know Your Customer (KYC) Form</h2>
            <form onSubmit={handleSubmit}>
                <section className="mb-6">
                    {/* Form Fields */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Customer's Name <span className='text-red-700 text-xl'>*</span></label>
                        <input type="text" name="customerName" className="w-full p-2 border rounded" placeholder="Full Name" value={formData.customerName} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Birthday</label>
                        <input type="date" name="birthday" className="w-full p-2 border rounded" value={formData.birthday} onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Email <span className='text-red-700 text-xl'>*</span></label>
                        <input type="email" name="email" className="w-full p-2 border rounded" placeholder="Email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Office Address</label>
                        <input type="text" name="officeAddress" className="w-full p-2 border rounded" value={formData.officeAddress} onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">House Address (if Individual)</label>
                        <input type="text" name="houseAddress" className="w-full p-2 border rounded" value={formData.houseAddress} onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Phone Number <span className='text-red-700 text-xl'>*</span></label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            className="w-full p-2 border rounded"
                            placeholder="Phone Number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Next of Kin <span className='text-red-700 text-xl'>*</span></label>
                        <input type="text" name="nextOfKin" className="w-full p-2 border rounded" placeholder="Full Name" value={formData.nextOfKin} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Next of Kin (Phone Number) <span className='text-red-700 text-xl'>*</span></label>
                        <input
                            type="tel"
                            name="nextOfKinPhoneNumber"
                            className="w-full p-2 border rounded"
                            placeholder="Phone Number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={formData.nextOfKinPhoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </section>

                {/* File Upload Section */}
                <section className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">Means of Identification</h3>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Please provide a clear copy of either of the following (Driver's License, NIN Card/Slip, or International Passport) in PDF or Image format. <span className='text-red-700 text-xl'>*</span></label>
                        <input type="file" name="idFile" className="w-full p-2 border rounded" accept=".pdf,image/*" onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">ID No <span className='text-red-700 text-xl'>*</span></label>
                        <input type="text" name="idNumber" className="w-full p-2 border rounded" placeholder="" value={formData.idNumber} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Check in Date <span className='text-red-700 text-xl'>*</span></label>
                        <input type="date" name="checkInDate" className="w-full p-2 border rounded" value={formData.checkInDate} onChange={handleChange} required />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Apartment(s) of your choice</label>
                        <div className="flex flex-col flex-wrap">
                            <label className="mr-4 mb-2">
                                <input type="checkbox" name="apartmentChoice" value="2 Bedroom Luxury" className="mr-2" onChange={handleChange} />
                                2 Bedroom Luxury
                            </label>
                            <label className="mr-4 mb-2">
                                <input type="checkbox" name="apartmentChoice" value="4 Bedroom Presidential" className="mr-2" onChange={handleChange} />
                                4 Bedroom Presidential
                            </label>
                            <label className="mr-4 mb-2">
                                <input type="checkbox" name="apartmentChoice" value="2 Bedroom Premium Economy" className="mr-2" onChange={handleChange} />
                                2 Bedroom Premium Economy
                            </label>
                            <label className="mr-4 mb-2">
                                <input type="checkbox" name="apartmentChoice" value="1 Bedroom Platinum" className="mr-2" onChange={handleChange} />
                                1 Bedroom Platinum
                            </label>
                            <label className="mr-4 mb-2">
                                <input type="checkbox" name='apartmentChoice' value="1 Bedroom Gold" className="mr-2" onChange={handleChange} />
                                1 Bedroom Gold
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Check Out Date <span className='text-red-700 text-xl'>*</span></label>
                        <input type="date" className="w-full p-2 border rounded" name="checkOutDate" value={formData.checkOutDate} onChange={handleChange} required />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Number of nights <span className='text-red-700 text-xl'>*</span></label>
                        <input type="text" className="w-full p-2 border rounded" name="numberOfNights" placeholder="" value={formData.numberOfNights} onChange={handleChange} required />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Occupation</label>
                        <input type="text" className="w-full p-2 border rounded" placeholder="" name="occupation" value={formData.occupation} onChange={handleChange} />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Instagram handle</label>
                        <input type="text" className="w-full p-2 border rounded" placeholder="@MilanoSignatures" name="instagramHandle" value={formData.instagramHandle} onChange={handleChange} />
                    </div>
                </section>

                {/* Additional Information Section */}
                <section className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">Additional Information</h3>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Comments</label>
                        <textarea className="w-full p-2 border rounded" rows="4" placeholder="Any additional comments" name="comments" value={formData.comments} onChange={handleChange}></textarea>
                    </div>
                </section>

                <button type="submit" className="w-full bg-[#CBA135] text-white p-2 rounded hover:bg-[#876B23]">Submit</button>
            </form>
        </div>
    );
};

export default RoomRentalAgreementForm;
