import React, { useContext, useEffect } from 'react'
import { Context } from '../components/AppContext';
import Nav from '../components/home/Nav';
import LowerNav from '../components/home/LowerNav';
import Hamburger from '../components/home/Hamburger';
import Footer from '../components/home/Footer';
import { motion } from 'framer-motion'

const Bar = () => {
    const {authUser,pageSetter,loggedIn,page,setPage,apartments, setApartments,fetchApartments} = useContext(Context);
    useEffect(()=>{
        setPage('bar');
    })
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
        </div>
        <div className='laundry-page-container ride-container gap-20 bar  margin-120 bar-page'>
            <div className="big">
                Have Fun And Drinks At The Milano Lounge
            </div>
            <div className="sm">
                We have exclusive lounge for our guests to have a good time.
            </div>
            <div className="button brown food" onClick={() => {
                    window.open('https://wa.me/message/D65RI5UBOW4TH1', '_blank')
                }}> Contact us</div>
        </div>

        <div className='mb'>
            <h1 className='h12'>What we offer</h1>
            <div className='inBtw'>
                <p className='pText'>
                Apart from the Luxury Accommodation and atmosphere, you get to also have the best of experience in our Mini Bar/Lounge with the latest drinks, cocktails and wines of your choice. We encourage all our guests to patronize and visit the bar when in the apartment.
                                </p>
                                <div className='imgCenter'>
                                <img className='barr' src="/images/cocktail.png" alt="not found" />

                                </div>
            </div>
        </div>
    <Footer />
    </motion.div>
      )
}

export default Bar