import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Nav from './Nav';
import NavButtons from './NavButtons';
import NavLinks from './NavLinks';

const DesktopLowerNav = ({ }) => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navClass = `fixed top-0 w-full p-6 z-10 ${scrolled ? 'bg-white' : 'bg-transparent '
    }`;

  return (
    <nav className={navClass}>
      <nav class="flex justify-between items-center">
        <a class="w-32 cursor-pointer " href="/">
          <img src="/images/milano-logo.png" alt="Milano" />
        </a>
        <NavLinks />
      </nav>
    </nav>
  );
}

export default DesktopLowerNav;
