import React from 'react'

const ReviewsForm = ({firstName,lastName, setFirstName, setLastName, mobile, setMobile, email, setEmail, occupation, setOccupation, comment, setComment}) => {
  return (
            <div className="form-container-review">
                <div className="flex-input">
                    <div className="flex-column gap-5">
                        <div className="input-title">first name</div>
                        <div className=""><input type="text" placeholder='John' value={firstName} onChange={(e)=> setFirstName(e.currentTarget.value)}/></div>
                    </div>
                    <div className="flex-column gap-5">
                        <div className="input-title">last name</div>
                        <div className=""><input type="text" placeholder='Doe' value={lastName} onChange={(e)=> setLastName(e.currentTarget.value)}/></div>
                    </div>
                </div>
                <div className="flex-input">
                    <div className="flex-column gap-5">
                        <div className="input-title">Mobile</div>
                        <div className=""><input type="number" placeholder='+234 8012 567 7893' value={mobile} onChange={(e)=> setMobile(e.currentTarget.value)}/></div>
                    </div>
                    <div className="flex-column gap-5">
                        <div className="input-title">Email address</div>
                        <div className=""><input type="text" placeholder='john.doe@gmail.com' value={email} onChange={(e)=> setEmail(e.currentTarget.value)}/></div>
                    </div>
                </div>
                <div className="flex-column gap-5">
                    <div className="input-title">Occupation</div>
                    <div className=""><input type="text" placeholder='Engineer' value={occupation} onChange={(e)=> setOccupation(e.currentTarget.value)}/></div>
                </div>
                <div className="flex-column gap-5">
                    <div className="input-title">Comment</div>
                    <div className=""><textarea type="textarea" placeholder='Leave a Comment' value={comment} onChange={(e)=> setComment(e.currentTarget.value)}/></div>
                </div>
            </div>
  )
}

export default ReviewsForm