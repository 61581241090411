import React from 'react'

const Rating = ({star, setStar}) => {
  return (
                <div className="flex-row gap-5">
                    <div className="" onClick={()=> setStar(1)}>
                        {star >0 ? <i class="fa-solid fa-star cursor"></i> : <i class="fa-regular fa-star"></i>}
                    </div>
                    <div className="" onClick={()=> setStar(2)}>
                        {star >1 ? <i class="fa-solid fa-star cursor"></i> : <i class="fa-regular fa-star"></i>}
                    </div>
                    <div className="" onClick={()=> setStar(3)}>
                        {star >2 ? <i class="fa-solid fa-star cursor"></i> : <i class="fa-regular fa-star"></i>}
                    </div>
                    <div className="" onClick={()=> setStar(4)}>
                        {star >3 ? <i class="fa-solid fa-star cursor"></i> : <i class="fa-regular fa-star"></i>}
                    </div>
                    <div className="" onClick={()=> setStar(5)}>
                        {star >4 ? <i class="fa-solid fa-star cursor"></i> : <i class="fa-regular fa-star"></i>}
                    </div>
                </div>
  )
}

export default Rating