import React, { useContext, useEffect } from 'react'
import { Context } from '../components/AppContext';
import Nav from '../components/home/Nav';
import LowerNav from '../components/home/LowerNav';
import Footer from '../components/home/Footer';
import Hamburger from '../components/home/Hamburger';
import { motion } from 'framer-motion'


const Ride = () => {
    const {authUser,pageSetter,loggedIn,page,setPage,apartments, setApartments,fetchApartments} = useContext(Context);
    useEffect(()=>{
        setPage('ride');
    })
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
        </div>
        <div className='food-page-container ride-page-container margin-120'>
            <div className="text-container flex-column gap-20">
                <div className="big">
                <p className='ride-text'>Book A Chauffeur Today</p> <span className='ride-text'> At </span> <span className='orange2'>Milano Signatures</span>
                </div>
                <div className="sm ride-text">
                We have the best drivers <br/>to take you to anywhere of your choice
                </div>
                <div className="button brown food bold" onClick={() => {
                    window.open('https://wa.me/message/D65RI5UBOW4TH1', '_blank')
                }}> Contact us</div>
            </div>
            <div className="img">
                {/* <img src="/images/Ridepage.png" alt="not found" /> */}
            </div>
        </div>
        {/* <h1 className='h11'> Categories</h1> */}


        <div className='center-div'>

        <div className='img-center'>
          <img className='img-small' src="/images/par2.png" alt="not found" />
            </div>
            {/* <div className='between-cars'>
                <h1>Chaffeur</h1>
                <h1>Full Tank</h1>
                <h1>Escort</h1>
            </div> */}

        </div>
        <h1 className='h12'>What We Offer</h1>
        <div className='div-center'>
                <p>Get a verified and professional driver for hotel, schools, offices, airport shuttle, convey guests to your location, meetings, gateway outings etc You don’t have to break the bank to ride the car of your choice. Our prices are affordable according to your budget.</p>
                </div>
                <div className='btnCenter'>
                 <div className="button brown food" onClick={() => {
                    window.open('https://wa.me/message/D65RI5UBOW4TH1', '_blank')
                }}> Contact us </div>
                </div>

                        <Footer/>

        {/* <div className="ride-categories-container flex-column center">
            <div className="section-title">Categories</div>
            <div className="ride-categories-container-grid">
                <div className="item flex-column center">
                    <div className="image-container"><img src="/images/ride-image-1.png" alt="" /></div>
                    <div className="car-name">TOYOTA PRADO</div>
                </div>
                <div className="item flex-column center">
                    <div className="image-container"><img src="/images/ride-image-2.png" alt="" /></div>
                    <div className="car-name">HONDA</div>
                </div>
                <div className="item flex-column center">
                    <div className="image-container"><img src="/images/ride-image-3.png" alt="" /></div>
                    <div className="car-name">KIA CERATO</div>
                </div>
            </div>
        </div> */}
    </motion.div>
      )
}

export default Ride