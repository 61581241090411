import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import Apartments from "./pages/Apartments";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Apartment from "./pages/Apartment";
import AppContext from "./components/AppContext";
import Food from "./pages/Food";
import Ride from "./pages/Ride";
import Laundry from "./pages/Laundry";
import Review from "./pages/Review";
import ContactUs from "./pages/ContactUs";
import Bar from "./pages/Bar";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Gallery from "./pages/Gallery";
import TermsAndConditions from "./pages/TermsAndConditions";
import About from "./pages/About";
import PaymentSuccess from "./pages/PaymentSuccess";
import AnimatedRoutes from "./pages/AnimatedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./contexts/AuthContext";
import WhatsApp from "./components/WhatsApp";


function App() {

  return (
    <AuthProvider>
      <AppContext>
        <Router>
          <AnimatedRoutes />
          <ToastContainer />
        </Router>
      </AppContext>
    </AuthProvider>

  );
}

export default App;
