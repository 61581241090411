import axios from "axios";

const API_URL = "https://milano-q5fy.onrender.com";


//Register User
const register = async (userData) => {
    const response = await axios.post(API_URL + "/api/users/register", userData);

    if(response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

//Login User
const login = async (userData) => {
    const response = await axios.post(API_URL + "/api/users/login", userData);

    if(response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
}

//Logout User
const logout = () => {
    localStorage.removeItem("user");
}

const authService = {
    register,
    logout,
    login
};

export default authService;