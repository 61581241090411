import React, { useEffect } from 'react'
import NavLogo from './NavLogo'
import NavButtons from './NavButtons'
import LowerNav from './LowerNav'

const Nav = ({loggedIn, page,pageSetter,setPage,setLoggedIn}) => {
  return (
    <div className=''>
      <LowerNav page={page} pageSetter={pageSetter} setPage={setPage}/>
    </div>
  )
}

export default Nav