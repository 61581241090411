import React from 'react'
import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'
const Footer = () => {
  return (
    <div className='footer-container jf flex-column center gap-1000'>
        <DesktopFooter />

        <MobileFooter />
        <br/>
        <div className="hr-footer"></div>

        <div className="footer-text m-5 copyright-text-footer"><i class="fa-solid fa-copyright"></i> {new Date().getFullYear()} Milano Signatures, Inc. All rights Reserved.</div>
    </div>
  )
}

export default Footer