import React, {createContext, useEffect, useState} from 'react'
import { fetchDataWithAxios } from '../config/fetchDataWithAxios';

export const Context = createContext(null);
const AppContext = ({children}) => {
  const [loggedIn, setLoggedIn] = useState( localStorage.getItem('jwt') ? true : false);
  const [page, setPage] = useState("");
  let demoApartments =[
    {
        "name": "Luxury Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/wf3bkoesngdvvoypeewc.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/tgyvsygf5chhybeyqdlw.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/eqkazndvoieo46gskh13.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/fhengrg1bz6op0c7eeih.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/e9aytmrmsa5hxt2pqhnf.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/ximow3czalmx6reesjwv.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309716/tgfhgot4d2mh0ye9vlpc.jpg"
        ],
        "description": "Our exquisite apartment offers a truly luxurious experience in the heart of Milano. With two spacious rooms, our apartment can comfortably accommodate up to fifteen guests, making it perfect for families or groups. You'll find three elegantly designed toilets, ensuring convenience and privacy for everyone. Additionally, the apartment features a well-equipped mini kitchen, allowing you to prepare light meals and snacks. Immerse yourself in the sophistication and comfort of Luxury Apartment, where every detail has been meticulously crafted to provide a memorable stay. Book your experience today and indulge in the epitome of luxury living in Milano.",
        "bedroom": 2,
        "toilet": 3,
        "guests": 6,
        "kitchen": "1 Mini kitchen",
        "price": 150000,
        "_id": "64a18ecc6602ee32cae1e669",
        "partyGuests": 15
    },
    {
        "name": "Presidential Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310203/vo2v1ilp3sqmjp8a1smx.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/yglxbpbb4fpjtiyxxkdf.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/dazxdgs86ehtszsys0uc.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/pjdvps5zjnmaost7llp7.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/ljcwqu8fr62chmm6oyab.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/n6nu9zpoutxxepowvzba.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/zkendmo3ne17iamkobjl.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/wjig8pp8fgbogxnmgxeu.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/whzm5i3yl552xrxwrxxf.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310203/kmmtq85g9zhknlynihgx.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688310202/blzaiz4kpejgktsekifz.jpg"
        ],
        "description": "Introducing the exquisite Presidential Apartment, a luxurious offering from Milano Signature apartment leasing company. This opulent apartment presents a remarkable blend of elegance and comfort, providing a truly grand experience.\n\nWith its four spacious rooms, the Presidential Apartment offers ample accommodation for up to 24 guests, ensuring a memorable stay for large groups or families. Each room is meticulously designed to exude sophistication and tranquility, providing a haven of relaxation.\n\nThe apartment boasts five meticulously maintained toilets, ensuring convenience and privacy for every guest. Additionally, the inclusion of a spacious kitchen adds a touch of practicality to this lavish setting, allowing guests to prepare meals and indulge in culinary delights.\n\nFrom the moment you step into the Presidential Apartment, you will be captivated by its exquisite decor, impeccable attention to detail, and the sense of luxury that permeates every corner. It is a haven where comfort and indulgence intertwine, promising an unforgettable experience for those seeking the finest accommodations in Milano.\n\nExperience the epitome of luxury at the Presidential Apartment, where sophistication and comfort seamlessly blend to create an extraordinary retreat.",
        "bedroom": 4,
        "toilet": 5,
        "guests": 12,
        "kitchen": "1 Spacious kitchen",
        "price": 150000,
        "_id": "64a190b36602ee32cae1e672",
        "partyGuests": 24
    },
    {
        "name": "Premium Economy Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/uxc1nlegur5hiem49xgf.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/hvvzzixppneql6ahibpm.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/ml9bcvlr5dphjv4bz3z4.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309545/uhhhw3epbt5q7ynrx3o2.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/hcv2rx0uyp3mf8gqbrzz.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/axwp85nikrk3v5wpwrwv.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/bv7qhxyi5jdknhwwzfrv.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309544/bkljbr0iqrlvubwxgwks.jpg"
        ],
        "description": "Introducing Premium Economy Apartment, an exquisite offering from Milano Signature Apartment Leasing Company. This luxurious apartment boasts a sophisticated design and a captivating ambiance, making it the ideal choice for those seeking comfort and elegance. With two spacious rooms, the apartment comfortably accommodates up to six guests, ensuring a delightful stay for families or groups of friends.\n\nThe meticulously designed interior features modern furnishings and tasteful decor, creating a harmonious blend of style and functionality. Each room offers a serene sanctuary, providing a tranquil atmosphere for relaxation and rejuvenation. With three well-appointed toilets, convenience and privacy are guaranteed for all occupants.\n\nThe apartment's well-equipped kitchen is a haven for culinary enthusiasts. Whether you prefer preparing a quick snack or indulging in a gourmet feast, the kitchen provides all the necessary amenities to satisfy your culinary desires.\n\nPremium Economy Apartment is more than just a place to stay—it's an experience that envelops you in luxury. Immerse yourself in the opulence and comfort of this remarkable space, offering an unforgettable stay in the heart of Milano Signature Apartment Leasing Company's prestigious offerings.",
        "bedroom": 2,
        "toilet": 3,
        "guests": 6,
        "kitchen": "1 kitchen",
        "price": 110000,
        "_id": "64a18e206602ee32cae1e666",
        "partyGuests": 15
    },
    {
        "name": "Economy Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309838/yodkevjymba8vsnafrhn.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309838/zgalfxbadosm6nv7huk9.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309838/nnllpw4ydurq8slszl4k.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309838/xcviisnqtf776i8x3tqr.jpg"
        ],
        "description": "The Economy Apartment, offered by Milano Signature, is a fantastic accommodation option for those seeking comfort and convenience. This apartment boasts two spacious rooms, providing ample space for a maximum of 10 guests. With three toilets available, guests can enjoy privacy and convenience during their stay. The well-equipped kitchen offers all the necessary amenities for preparing meals and snacks, ensuring a homely experience. Whether you're traveling with family or a larger group, the Economy Apartment offers a comfortable and affordable solution. Located in a prime area of Milano, guests can easily access popular attractions, dining establishments, and shopping centers. Experience a pleasant and enjoyable stay at the Economy Apartment from Milano Signature Apartment Leasing Company.",
        "bedroom": 2,
        "toilet": 3,
        "guests": 6,
        "kitchen": "1 kitchen",
        "price": 95000,
        "_id": "64a18f466602ee32cae1e66c",
        "partyGuests": 10
    },
    {
        "name": "Platinum Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309966/qq9taadda94dagc2vjz1.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309966/iaqvpkwkgei5bnm9bgey.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309966/biw2g6z0jjdbrb65gpaj.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309966/mqqk2pwfdt1dr7vwoupn.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309967/oukfjkqebgkyfm8kbsym.jpg"
        ],
        "description": "Platinum Apartment, presented by Milano Signature Apartment Leasing Company, offers an exceptional accommodation experience in a prime location. This elegant apartment is thoughtfully designed with one spacious room, providing ample comfort and style for up to three guests. The tastefully decorated interior exudes modern sophistication, creating a welcoming ambiance for a memorable stay.\n\nThe Platinum Apartment features two well-appointed toilets, ensuring convenience and privacy for guests. Additionally, a compact yet efficient mini kitchen is provided, equipped with essential amenities to cater to your culinary needs. Whether you prefer to prepare a quick snack or indulge in a gourmet meal, the kitchen offers the necessary facilities within its cleverly designed space.\n\nSituated in a desirable area, the Platinum Apartment offers easy access to nearby attractions, shopping districts, and dining establishments, allowing guests to immerse themselves in the vibrant atmosphere of Milano. With its stylish interiors, comfortable amenities, and prime location, the Platinum Apartment is the perfect choice for travelers seeking a luxurious and convenient accommodation option during their stay in Milano.",
        "bedroom": 1,
        "toilet": 2,
        "guests": 6,
        "kitchen": "1 Mini kitchen",
        "price": 75000,
        "_id": "64a18fc66602ee32cae1e66f",
        "partyGuests": 1
    },
    {
        "name": "Gold Apartment",
        "images": [
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309269/rcgmgojhbfr0otqyblkp.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309270/d63znamf8arloqqdwnxs.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309269/u8vxzutfyvfrmoylwabr.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309270/psp79ejbzigqiwzw2zqn.jpg",
            "https://res.cloudinary.com/damltcdhl/image/upload/v1688309270/pxliiub7gs8nqrhg6gli.jpg"
        ],
        "description": "Gold Apartment is a luxurious offering from Milano Signature, a renowned apartment leasing company. This exquisite apartment provides a perfect blend of comfort and style for those seeking a remarkable stay in the heart of Milano. With its elegant design and impeccable attention to detail, Gold Apartment promises a truly exceptional experience.\n\nFeaturing one spacious room, this apartment comfortably accommodates up to three guests, making it an ideal choice for couples or small families. The tastefully decorated interior creates a warm and inviting ambiance, ensuring a cozy retreat after a long day of exploration or work.\n\nGold Apartment boasts two well-appointed toilets, ensuring convenience and privacy for all occupants. Additionally, the apartment features a mini kitchen, equipped with modern appliances and essentials, allowing guests to prepare light meals and snacks at their convenience.\n\nWhether you're visiting Milano for business or pleasure, Gold Apartment offers a tranquil oasis in the bustling city. Experience the epitome of luxury living and indulge in the remarkable amenities provided by Milano Signature.",
        "bedroom": 1,
        "toilet": 2,
        "guests": 6,
        "kitchen": "1 Mini kitchen",
        "price": 55000,
        "_id": "64a18d0d6602ee32cae1e663",
        "partyGuests": 1
    }
]
  const [apartments, setApartments]= useState(demoApartments);

  useEffect(()=>{
    fetchApartments();
  },[])
  const authUser = () =>{
    const userJwt = localStorage.getItem('jwt');
    if(userJwt){
      setLoggedIn(true);
      return true
    }else{
      setLoggedIn(false);
      return false
    }
  }
  const pageSetter = (pageName) =>{
    setPage(pageName);
  }
  const fetchApartments = async ()=>{
    try {
        const result = await fetchDataWithAxios('get', '/apartments/getAllApartments');
        if(result?.data?.apartments){
            setApartments(result.data.apartments);
        }else{
            setApartments([]);
        }
    } catch (error) {
    }
}
  const value = {
    loggedIn,
    setLoggedIn,
    page,
    setPage,
    authUser,
    pageSetter,
    apartments,
    setApartments,
    fetchApartments
  }
  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  )
}

export default AppContext