import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Cardflip = ({ cardImages, cardDescriptions, cardTitle, cardLinks, buttonTitle }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleCardClick = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const handleButtonClick = (link) => {
    if (link) {
      const newWindow = window.open(link, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };



  const cardVariants = {
    expanded: {
      width: '400px',
    },
    collapsed: {
      width: '250px',
    },
  };

  return (
    <section className='py-16'>
      <div className='px-4 sm:px-6 lg:px-8 text-center'>
        <div className="section-text mb-4">Our Services</div>
      </div>
      <div className='flex flex-col md:flex-row justify-center items-center space-x-9 space-y-9 md:space-y-0'>
        {cardImages.map((image, index) => (
          <motion.div
            key={index}
            className={`card cursor-pointer h-[500px] md:h-[350px] bg-cover bg-center rounded-[20px] ${index === expandedIndex ? 'expanded' : ''}`}
            variants={cardVariants}
            initial='collapsed'
            animate={index === expandedIndex ? 'expanded' : 'collapsed'}
            transition={{ duration: 0.5 }}
            onClick={() => handleCardClick(index)}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <div className='card-content h-full flex flex-col justify-center'>
              <div className='p-6 card-footer bg-gray-800 bg-opacity-75 min-h-[100px] flex flex-col items-center justify-center'>
                <h2 className='text-xl font-semibold text-white text-center'>{cardTitle[index]}</h2>
                {index === expandedIndex && (
                  <React.Fragment>
                    <p className='mt-2 text-white text-center'>{cardDescriptions[index]}</p>
                    <button
                      onClick={() => handleButtonClick(cardLinks[index])}
                      className='mt-4 px-4 py-2 bg-[#CBA135] text-white rounded hover:bg-[#967e44]'
                    >
                      {buttonTitle[index]}
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Cardflip;
