import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apartmentService from "./apartmentService";

// Get apartments from local storage
const apartments = JSON.parse(localStorage.getItem('apartments'));

const initialState = {
    apartments: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    selectedItem: JSON.parse(localStorage.getItem('selectedItem')) || null,
}

const saveSelectedItemToLocalStorage = (selectedItem) => {
    localStorage.setItem('selectedItem', JSON.stringify(selectedItem));
};


// Get all apartments
export const getAllApartments = createAsyncThunk(
    'apartments/getAll',
    async (_, thunkAPI) => {
        try {
            ;
            return await apartmentService.getAllApartments();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const apartmentSlice = createSlice({
    name: 'apartment',
    initialState,
    reducers: {
        reset: (state) => initialState,
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
            saveSelectedItemToLocalStorage(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllApartments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllApartments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.apartments = action.payload;
            })
            .addCase(getAllApartments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    }
});


export const { reset, setSelectedItem } = apartmentSlice.actions;
export default apartmentSlice.reducer;