import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrivalInput from './ArrivalInput';
import DepartureInput from './DepartureInput';
import ApartmentSearch from './ApartmentSearch';
import GuestsSearch from './GuestsSearch';
import ApartmentSearchButton from './ApartmentSearchButton';

const DesktopSearch = ({
  arrivalInputType,
  setArrivalInputType,
  arrivalInput,
  setArrivalInput,
  departureInput,
  setDepartureInput,
  setDepartureInputType,
  departureInputType,
}) => {
  const [showApartmentDropDown, setShowApartmentDropDown] = useState(false);
  const [showGuestDropDown, setShowGuestDropDown] = useState(false);
  const [apartmentInputText, setApartmentInputText] = useState('Apartment');
  const [guestInputText, setGuestInputText] = useState('Guests');
  const apartments = [
    { id: "64a18d0d6602ee32cae1e663", name: " 2 Bed Luxury" },
    { id: "64a18fc66602ee32cae1e66f", name: " 4 Bed Presidential" },
    { id: "64a18ecc6602ee32cae1e669", name: " 2 Bed Premium" },
    { id: "64a18e206602ee32cae1e666", name: "2 Bed Economy" },
    { id: "64a18f466602ee32cae1e66c", name: "1 Bed Platinum" },
    { id: "64a190b36602ee32cae1e672", name: "1 Bed Gold" },
  ];
  const [guests, setGuests] = useState([
    1, 2, 3, 4, 5, 6, 7, 8
  ]);

  // State for selected apartment
  const [selectedApartment, setSelectedApartment] = useState(null);

  const navigate = useNavigate();

  return (
    <div className='homepage-container '>
      <div className='box-center desktop '>
      <ArrivalInput
        arrivalInputType={arrivalInputType}
        setArrivalInputType={setArrivalInputType}
        arrivalInput={arrivalInput}
        setArrivalInput={setArrivalInput}
      />
      <DepartureInput
        departureInput={departureInput}
        setDepartureInputType={setDepartureInputType}
        departureInputType={departureInputType}
        setDepartureInput={setDepartureInput}
      />

      <ApartmentSearch
        showApartmentDropDown={showApartmentDropDown}
        setShowApartmentDropDown={setShowApartmentDropDown}
        apartmentInputText={apartmentInputText}
        setApartmentInputText={setApartmentInputText}
        apartments={apartments}
        selectedApartment={selectedApartment}
        setSelectedApartment={setSelectedApartment}
      />
      <GuestsSearch
        showGuestDropDown={showGuestDropDown}
        setShowGuestDropDown={setShowGuestDropDown}
        guestInputText={guestInputText}
        guests={guests}
        setGuestInputText={setGuestInputText}
      />
      <ApartmentSearchButton selectedApartment={selectedApartment} />
    </div>
    </div>
  );
};

export default DesktopSearch;
