import React, { useContext } from 'react'
import Nav from '../components/home/Nav'
import LowerNav from '../components/home/LowerNav'
import Footer from '../components/home/Footer'
import Hamburger from '../components/home/Hamburger'
import ApartmentItem from '../components/home/ApartmentItem'
import { fetchDataWithAxios } from '../config/fetchDataWithAxios';
import { useState, useEffect } from 'react';
import Apartments from '../components/apartments/Apartments'
import { Context } from '../components/AppContext';
import ImageItem from '../components/home/ImageItem'
import { useNavigate } from 'react-router-dom'
import MobileGallery from '../components/gallery/MobileGallery'
import DesktopGallery from '../components/gallery/DesktopGallery'
import { motion } from 'framer-motion'

const Gallery = () => {
    const navigate = useNavigate();
    const {authUser,pageSetter,page, setPage,loggedIn,apartments, setApartments, fetchApartments} = useContext(Context);
    const [images, setImages] = useState([]);
    useEffect(()=>{
        setImages([
            "/images/gallery-one.jpg",
            "/images/gallery-two.jpg",
            "/images/gallery-three.jpg",
            "/images/gallery-four.jpg",
            "/images/gallery-five.jpg",
            "/images/gallery-six.jpg",
            "/images/gallery-seven.jpg",
        ])
    },[])
    useEffect(()=>{
        setPage('gallery');
    })

    useEffect(()=>{
        fetchApartments();
    }, [])
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
            <div className='apartments-container flex-column center margin-120'>
                {/* <div className="section-text">Our Gallery</div> */}
                <br/> <br/>
                <DesktopGallery />
                {/* <MobileGallery images={images} /> */}
                <div className="button brown"onClick={()=> {
                    window.location.assign('https://instagram.com/milanosignatureint?igshid=MzRlODBiNWFlZA==');
                }}>Visit our IG page</div>
            </div>
        </div>
        <Footer />
    </motion.div>
  )
}

export default Gallery