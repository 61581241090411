import React, { useContext, useEffect } from 'react'
import { Context } from '../components/AppContext';
import Nav from '../components/home/Nav';
import LowerNav from '../components/home/LowerNav';
import Hamburger from '../components/home/Hamburger';
import Footer from '../components/home/Footer';
import { motion } from 'framer-motion'


const Laundry = () => {
    const {authUser,pageSetter,loggedIn,page,setPage,apartments, setApartments,fetchApartments} = useContext(Context);
    useEffect(()=>{
        setPage('laundry');
    })
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },[])
  return (
        <motion.div
        initial={{width : 0}}
        animate={{width : "100%"}}
        exit={{x : window.innerWidth, transition: {duration : 0.1}}}
        >
        <Hamburger />
        <div className='homepage-container'>
            {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
            <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
        </div>
        <div className='laundry-page-container ride-container gap-20  margin-120'>
            <div className="big">
                Enjoy Premium Laundry Where We Wash, Dry And Fold Your Clothes At Affordable Prices
            </div>
            <div className="sm">
                You don't need to stress yourself while in the apartment
            </div>
            <div className="button brown food"  onClick={() => {
    window.open('https://www.wesolveitbeta.com/', '_blank');
  }}> Book now</div>
        </div>

        <div className='mb'>
            <h1 className='h12'>What We Offer</h1>
            <div className='inBtw'>
                <p className='pText'>
                With Experience in dry Cleaning and Laundry, ironing, stitching and stain removal services. We take care of your tedious chore in a fast, friendly and affordable manner with a personal touch. Each one of our laundry care specialists will ensure your garments are cleaned exactly as you like
                </p>
                <img className='shirt' src="/images/shirt.png" alt="not found" />
            </div>
        </div>
        <Footer />

    </motion.div>
      )
}

export default Laundry