import React from 'react'

const GridTwo = () => {
  return (
    <div className="grid two center flex-row center">
        <div className="images">
            <div className="">
                <img src="images/about-image.jpg" alt="" />
            </div>
        </div>
        <div className="about-desc flex-column gap-20">
                <div className="title flex-row center">
                    Mission and Vision Statement
                </div>
                <div className="summary flex-column gap-20">
                    <span className='flex-row gap-10'><i class="fa-solid fa-plus"></i> Our Mission is working towards becoming the market leader in the hospitality Industry in Nigeria.</span>
                    <span className='flex-row gap-10'><i class="fa-solid fa-plus"></i> Our Vision is to create long term  value for customers by providing them with quality and excellent service so they are proud to call Milano Apartment their home.</span>
                    <span className='flex-row gap-10'><i class="fa-solid fa-plus"></i> Our mission is to provide high quality accommodations for travellers seeking short term stay in our country/city.</span>
                    <span className='flex-row gap-10'><i class="fa-solid fa-plus"></i> Our Vision is to continuously innovate and improve our services to exceed our guests expectations and stay ahead of competition.</span>
                    <span className='flex-row gap-10'><i class="fa-solid fa-plus"></i> Our mission also includes to ensure that our guests have a seamless and stress-free experience from booking to checkout.</span>
                </div>
        </div>
    </div>
    )
}

export default GridTwo