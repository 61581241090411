import React from 'react'
import { useNavigate } from 'react-router-dom'
import MobileLowerNav from './MobileLowerNav';
import DesktopLowerNav from './DesktopLowerNav';

const LowerNav = ({loggedIn,page,pageSetter,setPage}) => {
  const navigate = useNavigate();

  return (
    <>
    {/* <MobileLowerNav page={page}/> */}
    <DesktopLowerNav page={page}/>
    </>

  )
}

export default LowerNav