import React, { useContext, useEffect } from 'react'
import Nav from '../components/home/Nav'
import LowerNav from '../components/home/LowerNav'
import Hero from '../components/home/Hero'
import Services from '../components/home/Services'
import Apartments from '../components/home/Apartments'
import Instagram from '../components/home/Instagram'
import Reviews from '../components/home/Reviews'
import NewsPaper from '../components/home/NewsLetter'
import Footer from '../components/home/Footer'
import Hamburger from '../components/home/Hamburger'
import { Context } from '../components/AppContext';
import GridOne from '../components/about/GridOne'
import GridTwo from '../components/about/GridTwo'
import GridThree from '../components/about/GridThree'
import { motion } from 'framer-motion'

const About = () => {
  const {authUser,pageSetter,loggedIn,page,setPage,apartments, setApartments,fetchApartments} = useContext(Context);
  useEffect(()=>{
    setPage('about');
  })
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[])
  return (
    <motion.div
    initial={{width : 0}}
    animate={{width : "100%"}}
    exit={{x : window.innerWidth, transition: {duration : 0.1}}}
  >
    <Hamburger />
    <div className='homepage-container'>
        {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
        <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/>
    </div>
    <div className="about-container flex-column center margin-120">
        {/* <div className="section-title">About Milano</div> */}
        <br/> <br/>
        <div className="grid-items z-0">
            <GridOne />
            <GridTwo />
            <GridThree />
        </div>

    </div>
        <Footer />
    </motion.div>
  )
}

export default About