import React from 'react'

const DepartureInput = ({departureInput, setDepartureInputType, departureInputType, setDepartureInput}) => {
  return (
    <div className='item' id='three'>
        <div className='flex-row center gap-10 date-picker'>
            <div className='item-flex'><i class="fa-regular fa-calendar-days"></i></div>
            <input type={departureInputType == 'text' ? 'text' : 'date'} placeholder='Departure' onFocus={()=>setDepartureInputType('date')} onBlur={()=>setDepartureInputType('text')} value={departureInput} onChange={(e)=>setDepartureInput(e.currentTarget.value)}/>
        </div>
        <div className='item-flex'> <div className='thin-vertical'></div></div>
    </div>
  )
}

export default DepartureInput