import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'

const PaymentSuccess = () => {
    const navigate = useNavigate();
    return (
        <motion.div>
            <div className='forgot-password-container flex-column center success'>
                <div className="forgot-password-logo flex-column center gap-20">
                    <div className="logo-forgot-password flex-row center"><img src="/images/logo.png" alt="" /></div>
                    <div className="check-container"><i class="fa-solid fa-check"></i></div>
                    <div className="desc flex-row center text-center">Payment Successful</div>

                    <div className="desc flex-row center text-center">Thank you for your payment. Your transaction has been completed</div>
                </div>

                <div className="forgot-password-input flex-column center gap-20">
                    <div className="button brown" onClick={() => {
                        navigate('/');
                    }}>Back to Milano</div>
                </div>
            </div>
        </motion.div>
    )
}

export default PaymentSuccess