import React from 'react'
import Modal from 'react-modal';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
        width: '80vw',
      height: '80vh',
    },
  };
  
  Modal.setAppElement('#root'); 

const ApartmentItem = ({ item }) => {
    const navigate = useNavigate()
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
    const priceFormatter = () => {
        let price = formatter.format(item.price);
        price = price.substring(4);
        return price
    }

    const handleClick = () => {
        navigate(`/apartments/${item.name}`)
    }

    return (
        <div className="item flex-column apartments-slide-item z-0">
            <div className="apartment-image flex-column center m-10-top">
                <div className="img-apartment">
                    <Swiper
                        speed={2000}
                        spaceBetween={50}
                        direction={"vertical"}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation, Autoplay]}
                        className="swipper-apartment-image"
                    >
                        {item?.images?.slice(0, 5).map((item, index) => (
                            <SwiperSlide key={index}>
                                <img src={item} alt="" />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="apartment-title-and-price flex-row">
                <Link to={`/apartments/${item.name}`} className="apartment-title">{item?.name}</Link>
                <div className="apartment-price-flex flex-column">
                    <div className="apartment-price">#{priceFormatter()}</div>
                    <div className="sm">per night</div>
                </div>
            </div>
            <div className="apartment-desc flex-column gap-10">
                <div className="apartment-size">{item?.bedroom} Bedroom Apartment</div>
                <div className="apartment-icons flex-row gap-20">
                    <div className="item flex-row dark-pry-color">
                        <div className="icon"><i class="fa-solid fa-bed"></i></div>
                        <div className="number">&nbsp;{item?.bedroom}</div>
                    </div>
                    <div className="item flex-row dark-pry-color">
                        <div className="icon"><i class="fa-solid fa-toilet"></i></div>
                        <div className="number">&nbsp; {item?.toilet}</div>
                    </div>
                    <div className="item flex-row dark-pry-color">
                        <div className="icon"><i class="fa-solid fa-user"></i></div>
                        <div className="number">&nbsp; {item?.guests} {item?.guests > 1 ? `guests` : `guest`}</div>
                    </div>
                    <div className="item flex-row dark-pry-color">
                        <div className="icon"><i class="fa-solid fa-fire-burner"></i></div>
                        <div className="number">&nbsp; {item?.kitchen}</div>
                    </div>
                </div>
            </div>
            <div className="book-cta dark-pry-color-btn brown">
                <button onClick={handleClick}>Book now</button>
            </div>
        </div>
    )
}

export default ApartmentItem