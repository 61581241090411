import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';

const Hamburger = ({ loggedIn, setLoggedIn }) => {
  const [hamburger, setHamburger] = useState('close');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout, currentUser } = useAuth();

  const onLogOut = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      toast.error(error.message);
    }
  }


  return (
    <>
      <div className='mobile-container'>
        <div className="mobile-logo" onClick={() => navigate('/')}><img src="/images/milano-logo.png" alt="" /></div>
        <div className="triangle-square">
          <a href='/apartments'>Book <br /> Now</a>
        </div>
        <div className="hamburger-icon" onClick={() => setHamburger('open')}>
          <i class="fa-solid fa-bars"></i>
        </div>
      </div>
      <div className={`hamburger-menu ${hamburger == 'open' ? 'open-hamburger' : 'closed-hamburger'}`}>
        <div className="hamburger-top flex-row width-100">
          <div className="">
            <img src="/images/single-apartment-logo.png" alt="" />
          </div>
          <div className={`close-hamburger ${hamburger == 'open' ? 'open' : 'close'}`} onClick={() => setHamburger('close')}> X </div>
        </div>
        <div className='width-100 padding-10'>
          <div className='width-100'>
            <ul className={`${hamburger == 'open' ? 'open' : 'close'} flex-column gap-30 hamburger-links-list`}>
              <li className='flex-column gap-10' onClick={(e) => {
                e.preventDefault();
                navigate('/')
              }}>
                <a href="#">Home</a>
                <hr />
              </li>
              <li className='flex-column gap-10' onClick={(e) => {
                e.preventDefault();
                navigate('/about')
              }}>
                <a href="#">About Us</a>
                <hr />
              </li>
              <li className='flex-column gap-10' onClick={(e) => {
                e.preventDefault();
                navigate('/apartments')
              }}>
                <a href="#">Available listings</a>
                <hr />
              </li>
              <li className='flex-column gap-10' onClick={(e) => {
                e.preventDefault();
                navigate('/gallery')
              }}>
                <a href="#">Gallery</a>
                <hr />
              </li>
              <li className='flex-column gap-10' onClick={(e) => {
                e.preventDefault();
                navigate('/contact')
              }}>
                <a href="#">Contact Us</a>
                <hr />
              </li>
              {/* <li className='flex-column gap-10 center'><a href="/apartments" className='hamburger-button sm button brown'>Available Shortlets</a></li> */}
            </ul>
          </div>

        </div>
      </div>

    </>
  )
}

export default Hamburger