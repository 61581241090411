import React, { useContext } from 'react'
import Nav from '../components/home/Nav'
import LowerNav from '../components/home/LowerNav'
import Footer from '../components/home/Footer'
import Hamburger from '../components/home/Hamburger'
import ApartmentItem from '../components/home/ApartmentItem'
import { fetchDataWithAxios } from '../config/fetchDataWithAxios';
import { useState, useEffect } from 'react';
import Apartments from '../components/apartments/Apartments'
import { Context } from '../components/AppContext';
import Map from '../components/contact/Map'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { useForm, ValidationError } from "@formspree/react";

const ContactUs = () => {
    const { authUser, pageSetter, page, setPage, loggedIn, apartments, setApartments, fetchApartments } = useContext(Context);
    useEffect(() => {
        setPage('contact');
    })

    useEffect(() => {
        fetchApartments();
    }, [])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    const navigate = useNavigate();
    const [state, handleSubmit] = useForm("moqogwbr");
    if (state.succeeded) {
        navigate("/contact-us-success");
    }

    return (
        <motion.div
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
        >
            <Hamburger />
            <div className='homepage-container mb-6'>
                {/* <Nav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage}/> */}
                <LowerNav loggedIn={loggedIn} page={page} pageSetter={pageSetter} setPage={setPage} />
            </div>
            <section class="text-gray-600 body-font relative">
                <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div
                        class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d31706.45466035934!2d3.3597136201805093!3d6.608743507477779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s16%20Olabisi%20Street%20Magodo%2C%20Shangisha%2C%20Lagos.!5e0!3m2!1sen!2sng!4v1701847384904!5m2!1sen!2sng"
                            width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                            <div class="lg:w-1/2 px-6">
                                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                                <p class="mt-1">16 Olabisi Street Magodo, Shangisha, Lagos.</p>
                            </div>
                            <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                                <a class="text-[#CBA135] leading-relaxed">milanosignatures@gmail.com</a>
                                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                                <p class="leading-relaxed">+234 90874 29197</p>
                            </div>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                        class="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
                        <p class="leading-relaxed mb-5 text-gray-600">
                        </p>
                        <div class="relative mb-4">
                            <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                            <input
                                required
                                type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-[#CBA135]  focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            <ValidationError
                                prefix='Name'
                                field="name"
                                errors={state.errors}
                            />
                        </div>
                        <div class="relative mb-4">
                            <label for="number" class="leading-7 text-sm text-gray-600">Phone Number:</label>
                            <input
                                required
                                type="number" id="number" name="number" class="w-full bg-white rounded border border-gray-300 focus:border-[#CBA135]  focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            <ValidationError
                                prefix='Number'
                                field="number"
                                errors={state.errors}
                            />
                        </div>
                        <div class="relative mb-4">
                            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                            <input
                                required
                                type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-[#CBA135]  focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            <ValidationError
                                prefix='Email'
                                field="email"
                                errors={state.errors}
                            />
                        </div>
                        <div class="relative mb-4">
                            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                            <textarea
                                required
                                id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-[#CBA135] focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            <ValidationError
                                prefix='Message'
                                field="message"
                                errors={state.errors}
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={state.submitting}
                            className={`text-white bg-[#CBA135] border-0 py-2 px-6 focus:outline-none rounded text-lg ${state.submitting ? 'loading' : ''
                                }`}
                        >
                            {state.submitting ? (
                                <div className="spinner"></div>
                            ) : (
                                'Send Message'
                            )}
                        </button>
                    </form>
                </div>
            </section>
            <Footer />
        </motion.div>
    )
}

export default ContactUs